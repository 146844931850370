import {
  StyleSheet,
  Image,
  ActivityIndicator,
  useWindowDimensions,
  ScrollView,
  TextInput,
  FlatList,
  Pressable,
  Switch,
} from "react-native";
import React, { useState, useEffect } from "react";
import { BoldText } from "../../constants/StyledText";
import { Text, View } from "../../constants/Themed";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";
import { API, graphqlOperation } from "aws-amplify";

import { Feather } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import SearchBarMobile from "../../components/mobile/SearchBar";



//note, you have removed the line about limit from the top of this query (where it gets defined as an int) and added a limit of 2000. If you don't do this, you don't get all of the results!
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $nextToken: String) {
    listUsers(filter: $filter, limit: 9999, nextToken: $nextToken) {
      items {
        id
        surname
        aaa
        preferredFirstName
        type
        photoURL
        isActive
        Enrolments {
          items {
            groupingData1
            groupingData2
            groupingData3
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

const AdminAddShoutOut = ({ navigation }) => {
  const colorScheme = useColorScheme();
  const [clicked, setClicked] = useState(false);
  const [caption, setCaption] = useState("");
  const [momentDateDay, setMomentDateDay] = useState("");
  const [momentDateMonth, setMomentDateMonth] = useState("");
  const [momentDateYear, setMomentDateYear] = useState("");
  const [momentDate, setMomentDate] = useState("");
  const [triggerWarning, setTriggerWarning] = useState(false);
  const [tagged, setTagged] = useState([])
  const [filteredData, setFilteredData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [userArrayJustStaff, setUserArrayJustStaff] = useState([]);
  const [searchUsers, setSearchUsers] = useState("");
  const [search, setSearch] = useState("");
  const [filteredDataUsers, setFilteredDataUsers] = useState([]);
  const [clickedUsers, setClickedUsers] = useState(false);
  const [staffLeader, setStaffLeader] = useState([]);

  useEffect(() => {
    fetchUsersList();
  }, []);


  const fetchUsersList = async () => {
    const response = await API.graphql(
      graphqlOperation(listUsers, {
        filter: {
          type: {
            ne: "ALUMNI",
            // eq: "CHILD"
          },
        },
      })
    );

    setFilteredData(response.data.listUsers.items);
    setMasterData(response.data.listUsers.items);
    setUserArrayJustStaff(
      response.data.listUsers.items.filter((item) => item.type === "STAFF" || item.type === "ADMIN")
    );
  };
  
  //combine the one off dates into a single string when any of them change
  useEffect(() => {
    setMomentDate(
      momentDateYear.concat("-", momentDateMonth, "-", momentDateDay)
    );
  }, [momentDateDay, momentDateMonth, momentDateYear]);


  const removeFromUsersArray = (id) => {
    setSearchUsers("");
    setTagged((user) =>
      user.filter((obj) => {
        return obj.id !== id;
      })
    );
  };

  const addToUsersArray = (item) => {
    if (!tagged.some((u) => u.id === item.id)) {
      //if the user isn't already in the array
      setSearchUsers(""); //reset the search box
      setTagged((oldArray) => [...oldArray, item]); //then add the user to the end of the array
    } else {
      alert("You can't add the same user twice!");
    }
  };

  const addToArray = (item) => {
    setStaffLeader((oldArray) => [...oldArray, item]);
  };

  const removeFromArray = (id) => {
    setSearch("");
    setStaffLeader((user) =>
      user.filter((obj) => {
        return obj.id !== id;
      })
    );
  };


  const searchFilter = (text) => {
    if (text) {
      const newData = userArrayJustStaff.filter((item) => {
        const itemData = item.aaa ? item.aaa.toUpperCase() : "".toLowerCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });

      setFilteredData(newData);
      setSearch(text);
    } else {
      setFilteredData(userArrayJustStaff);
      setSearch(text);
    }
  };

  const searchFilterUsers = (text) => {
    if (text) {
      const newData = masterData.filter((item) => {
        const itemData = item.aaa ? item.aaa.toUpperCase() : "".toLowerCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });

      setFilteredDataUsers(newData);
      setSearchUsers(text);
    } else {
      setFilteredDataUsers(masterData);
      setSearchUsers(text);
    }
  };

  return (
    <ScrollView
      contentContainerStyle={{
        width: 900,
        alignSelf: "center",
        alignItems: "center",
        backgroundColor: "transparent",
      }}
    >
      {/* Caption */}

      <View style={styles.textInputContainer}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={caption ? Colors.palette.logoGreen : "transparent"}
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Caption:
          </Text>
        </View>
        <TextInput
          style={[
            styles.textInputBox,
            {
              outlineStyle: "none",
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
          onChangeText={setCaption}
          value={caption}
          maxLength={70}
        />
      </View>

      {/* Date */}

      <View style={styles.textInputContainer}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={
              momentDate.length < 10 ? "transparent" : Colors.palette.logoGreen
            }
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Date:
          </Text>
        </View>
        <View
          style={{
            width: 315,
            flexDirection: "row",
            backgroundColor: "transparent",
          }}
        >
          <Picker
            style={[
              styles.textInputBox,
              {
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            // itemStyle={{fontFamily: 'avenir-next', color: 'red'}}
            selectedValue={momentDateDay}
            onValueChange={(itemValue, itemIndex) =>
              setMomentDateDay(itemValue)
            }
          >
            <Picker.Item label="Day" value="" />
            <Picker.Item label="1" value="01" />
            <Picker.Item label="2" value="02" />
            <Picker.Item label="3" value="03" />
            <Picker.Item label="4" value="04" />
            <Picker.Item label="5" value="05" />
            <Picker.Item label="6" value="06" />
            <Picker.Item label="7" value="07" />
            <Picker.Item label="8" value="08" />
            <Picker.Item label="9" value="09" />
            <Picker.Item label="10" value="10" />
            <Picker.Item label="11" value="11" />
            <Picker.Item label="12" value="12" />
            <Picker.Item label="13" value="13" />
            <Picker.Item label="14" value="14" />
            <Picker.Item label="15" value="15" />
            <Picker.Item label="16" value="16" />
            <Picker.Item label="17" value="17" />
            <Picker.Item label="18" value="18" />
            <Picker.Item label="19" value="19" />
            <Picker.Item label="20" value="20" />
            <Picker.Item label="21" value="21" />
            <Picker.Item label="22" value="22" />
            <Picker.Item label="23" value="23" />
            <Picker.Item label="24" value="24" />
            <Picker.Item label="25" value="25" />
            <Picker.Item label="26" value="26" />
            <Picker.Item label="27" value="27" />
            <Picker.Item label="28" value="28" />
            <Picker.Item label="29" value="29" />
            <Picker.Item label="30" value="30" />
            <Picker.Item label="31" value="31" />
          </Picker>
          <Picker
            style={[
              styles.textInputBox,
              {
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            selectedValue={momentDateMonth}
            onValueChange={(itemValue, itemIndex) =>
              setMomentDateMonth(itemValue)
            }
          >
            <Picker.Item label="Month" value="" />
            <Picker.Item label="Jan" value="01" />
            <Picker.Item label="Feb" value="02" />
            <Picker.Item label="Mar" value="03" />

            <Picker.Item label="Apr" value="04" />
            <Picker.Item label="May" value="05" />
            <Picker.Item label="Jun" value="06" />

            <Picker.Item label="Jul" value="07" />
            <Picker.Item label="Aug" value="08" />
            <Picker.Item label="Sep" value="09" />

            <Picker.Item label="Oct" value="10" />
            <Picker.Item label="Nov" value="11" />
            <Picker.Item label="Dec" value="12" />
          </Picker>
          <Picker
            style={[
              styles.textInputBox,
              {
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            selectedValue={momentDateYear}
            onValueChange={(itemValue, itemIndex) =>
              setMomentDateYear(itemValue)
            }
          >
            <Picker.Item label="Year" value="" />
            <Picker.Item label="2021" value="2021" />
            <Picker.Item label="2022" value="2022" />
            <Picker.Item label="2023" value="2023" />
            <Picker.Item label="2024" value="2024" />
          </Picker>
        </View>
      </View>

      {/* Pupil(s) */}

<View style={[styles.textInputContainer, { alignItems: "baseline" }]}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={
              tagged.length < 1 ? "transparent" : Colors.palette.logoGreen
            }
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Tag:
          </Text>
        </View>

        <View
          style={[
            styles.textInputBox,
            {
              outlineStyle: "none",
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
        >
          {tagged.map((user) => {
            //Item in the list of users to add the tag to
            return (
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: Colors[colorScheme].background,
                }}
                key={user.id}
              >
                <Text
                  style={{ color: Colors[colorScheme].text, fontSize: 16 }}
                  numberOfLines={1}
                >
                  {user.preferredFirstName} {user.surname}
                </Text>
                <Pressable onPress={() => removeFromUsersArray(user.id)}>
                  <Feather
                    name="trash-2"
                    size={20}
                    color={Colors.palette.softRed}
                  />
                </Pressable>
              </View>
            );
          })}

          {/* {usersToBadge.length < 1 ? ( */}
          <>
            <SearchBarMobile
              searchPhrase={searchUsers}
              setSearchPhrase={(text) => searchFilterUsers(text)}
              clicked={clickedUsers}
              setClicked={setClickedUsers}
            />

            {searchUsers ? (
              <FlatList
                data={filteredDataUsers}
                numColumns={1}
                renderItem={({ item }) => (
                  // <ThumbnailTimeline id={item.id}/>
                  <Pressable
                    onPress={() =>
                      addToUsersArray({
                        id: item.id,
                        surname: item.surname,
                        preferredFirstName: item.preferredFirstName,
                      })
                    }
                  >
                    <View style={{ backgroundColor: "transparent" }}>
                      <Text
                        style={{
                          color: Colors[colorScheme].text,
                          fontSize: 16,
                          paddingVertical: 5,
                          marginLeft: 10,
                        }}
                      >
                        {item.preferredFirstName} {item.surname}
                      </Text>
                    </View>
                  </Pressable>
                )}
                keyExtractor={(item) => item.id}
                key={4}
              />
            ) : null}
          </>
          {/* ) : null} */}
        </View>
      </View>


      {/* Staff */}

{/* STAFF  */}
<View style={[styles.textInputContainer, { alignItems: "baseline" }]}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={
              staffLeader.length < 1 ? "transparent" : Colors.palette.logoGreen
            }
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Staff name:
          </Text>
        </View>

        <View
          style={[
            styles.textInputBox,
            {
              outlineStyle: "none",
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
        >
          {staffLeader.map((user) => {
            //Item in the list of users to add the badge to
            return (
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: Colors[colorScheme].background,
                }}
                key={user.id}
              >
                <Text
                  style={{ color: Colors[colorScheme].text, fontSize: 16 }}
                  numberOfLines={1}
                >
                  {user.preferredFirstName} {user.surname}
                </Text>
                <Pressable onPress={() => removeFromArray(user.id)}>
                  <Feather
                    name="trash-2"
                    size={20}
                    color={Colors.palette.softRed}
                  />
                </Pressable>
              </View>
            );
          })}

          {staffLeader.length < 1 ? (
            <>
              <SearchBarMobile
                searchPhrase={search}
                setSearchPhrase={(text) => searchFilter(text)}
                clicked={clicked}
                setClicked={setClicked}
              />

              {search ? (
                <FlatList
                  data={filteredData}
                  numColumns={1}
                  renderItem={({ item }) => (
                    // <ThumbnailTimeline id={item.id}/>
                    <Pressable
                      onPress={
                        () =>
                          addToArray({
                            id: item.id,
                            surname: item.surname,
                            preferredFirstName: item.preferredFirstName,
                          })
                        // {highlightSelection(item.id)}}
                      }
                    >
                      <View style={{ backgroundColor: "transparent" }}>
                        <Text
                          style={{
                            color: Colors[colorScheme].text,
                            fontSize: 16,
                            paddingVertical: 5,
                            marginLeft: 10,
                          }}
                        >
                          {item.preferredFirstName} {item.surname}
                        </Text>
                      </View>
                    </Pressable>
                  )}
                  keyExtractor={(item) => item.id}
                  key={4}
                />
              ) : null}
            </>
          ) : null}
        </View>
      </View>


      {/* Image */}


      {/* Upload button */}
      <View style={{ backgroundColor: "transparent", alignItems: "center" }}>
        {triggerWarning ? (
          <View
            style={{
              backgroundColor: Colors[colorScheme].almostBackground,
              padding: 10,
              borderRadius: 10,
              borderColor: Colors.palette.softRed,
              borderWidth: 1,
              margin: 10,
            }}
          >
            <Text style={{ color: Colors.palette.softRed }}>
              There are some issues with the form. Please fix these before
              trying again.
            </Text>
          </View>
        ) : null}
        <Pressable
          // onPress={submitRecords}
          style={{
            alignItems: "center",
            width: 120,
            backgroundColor: Colors.palette.logoGreen,
            padding: 10,
            margin: 20,
            borderRadius: 10,
          }}
        >
          <Feather
            name="upload"
            size={48}
            color={Colors[colorScheme].background}
          />
          <BoldText
            style={{
              textAlign: "center",
              color: Colors[colorScheme].background,
            }}
          >
            Submit details
          </BoldText>
        </Pressable>
      </View>
    </ScrollView>
  );
};
export default AdminAddShoutOut;

const styles = StyleSheet.create({
  textInputBox: {
    borderWidth: 1,
    margin: 10,
    padding: 10,
    borderRadius: 8,
    fontSize: 16,
    fontFamily: "avenir-next",
    width: 250,
  },
  textInputLabel: { fontSize: 16, marginHorizontal: 10, fontWeight: "800" },
  textInputContainer: {
    flexDirection: "row",
    padding: 5,
    width: 600,
    backgroundColor: "transparent",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textInputLabelAndIconContainer: {
    backgroundColor: "tranparent",
    flexDirection: "row",
    alignItems: "flex-end",
    width: 150,
  },
  switchRowContainer: {
    backgroundColor: "transparent",
    flexDirection: "row",
    flex: 1,
    padding: 5,
    // alignItems: "s",
    justifyContent: "flex-start",
    alignSelf: "stretch",
    marginLeft: 20,
  },
  switchGroupContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    margin: 10,
    padding: 10,
    borderRadius: 8,
    fontSize: 16,
    width: 300,
  },
  switchLabel: { fontSize: 16, marginHorizontal: 40 },
});
