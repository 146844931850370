import { StyleSheet, FlatList, Pressable, useWindowDimensions, Animated, ScrollView} from 'react-native';
import React, {useState, useRef, useEffect,} from 'react';
import { View, Text } from '../../constants/Themed';
import { BoldText } from "../../constants/StyledText";
import { RootTabScreenProps } from '../../navigation/types';
import DUMMYQUERYSHOUTOUTS from '../../models/DummyQueryShoutOuts.json';
import ThumbnailShoutOut from '../../components/desktop/ThumbnailShoutOut';
import { Feather } from "@expo/vector-icons";
import Colors from '../../constants/Colors';
import useColorScheme from "../../hooks/useColorScheme";
import  SearchBar from "../../components/SearchBar";
import OpportunityIcon from '../../../assets/icons/Opportunity';
import TimelineIcon from '../../../assets/icons/Timeline';
import HousePointsWeekly from '../../components/HousePointsWeekly';
import FeedItemShoutOut from '../../components/mobile/FeedItemShoutOut';


export default function ShoutOutsRoot({ navigation }: RootTabScreenProps<'ShoutOuts'>) {
  const {width} = useWindowDimensions();
  const [searchBarToggle, setSearchBarToggle] = useState(false)
  const colorScheme = useColorScheme();

// const ToggleSearchBar = () => {
//   setSearchBarToggle(!searchBarToggle)
// } 


// useEffect( ()=> {
//   navigation.setOptions({
//     headerRight:()=>(

    
//       <View style={{right: (width - 1200)/2 }}>
//       <Pressable
//       onPress={ToggleSearchBar}
//       ><Feather
//             name="search"
//             size={24}
//             color={searchBarToggle ?  Colors.palette.logoGreen : Colors[colorScheme].buttons}
//             style={{ marginRight: 10 }}
//           /></Pressable></View>

//     )
//   })
// }, [ToggleSearchBar]);

//   const searchBarAnim = useRef(new Animated.Value(-145)).current;
//   useEffect(() => {
//     if (searchBarToggle) {
//       Animated.timing(searchBarAnim, {
//         toValue: 0,
//         duration: 150,
//         useNativeDriver: true,
//       }).start();
//     } else {
//       Animated.timing(searchBarAnim, {
//         toValue: -70,
//         duration: 150,
//         useNativeDriver: true,
//       }).start();
//     }
//   }, [searchBarToggle]);

//   const [searchPhrase, setSearchPhrase] = useState("");
//   const [clicked, setClicked] = useState(false);

  const numberOfColumns = 4 //AJL: You will want to make this dynamic at some point
  
  
  
  const renderItem = ({item}:any) => {
    if (searchPhrase === "") {
      return <ThumbnailShoutOut shoutout={item} />;
    }
    if (item.name.toUpperCase().includes(searchPhrase.toUpperCase().trim().replace(/\s/g, ""))) {
      return <ThumbnailShoutOut shoutout={item} />;
    }

};
  
  
  
// if (width > 1000) {
  return (


  
  

    



    <View style={[styles.root, {backgroundColor:
     Colors[colorScheme].almostBackground
     }]}>


<HousePointsWeekly />
{/* <FlatList
      data={DUMMYQUERYSHOUTOUTS}
      // horizontal={true}
      numColumns = {numberOfColumns}
      renderItem={({ item }) => <FeedItemShoutOut shoutout={item} />} 
      keyExtractor={(item) => item.id}
      
    /> */}
{/* search bar */}
{/* <Animated.View style={{ transform: [{ translateY: searchBarAnim }] }}>
<SearchBar
  searchPhrase={searchPhrase}
  setSearchPhrase={setSearchPhrase}
  clicked={clicked}
  setClicked={setClicked}

  />

</Animated.View>



<Animated.View style={{ transform: [{ translateY: searchBarAnim }],
flex: 1
}}>


    <FlatList
      data={DUMMYQUERYSHOUTOUTS}
      // horizontal={true}
      numColumns = {numberOfColumns}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      
    />



</Animated.View> */}


{/* <View style={{backgroundColor: Colors[colorScheme].background,
    borderRadius: 10,
    padding: 10,
    width:'80%'
    }}>
    <BoldText style={{fontSize: 20, color:Colors[colorScheme].text}}>Welcome to Recognition</BoldText>
    <Text>Use the menu on the left of the screen to explore...</Text>
    <View style={{paddingVertical:20}}>
    <View style={{flexDirection: 'row',  alignItems: 'center', paddingVertical: 10}}>
    <View style={{width:30, height: 30, }}>
    <OpportunityIcon color={Colors.palette.logoGreen} />
    
    </View>
    <BoldText style={{color:Colors[colorScheme].text, fontSize:16}}>Opportunities</BoldText>
    </View>
    
    <Text>The activities available to children at Carr Manor Community School.</Text>
    </View>
    <View style={{paddingVertical:20}}>
    <View style={{flexDirection: 'row',alignItems: 'center', paddingVertical: 10}}>
    <View style={{width:30, height: 30}}>
    <TimelineIcon color={Colors.palette.logoGreen} />
    
    </View>
    <BoldText style={{color:Colors[colorScheme].text, fontSize:16}}>Timelines</BoldText>
    </View>
    
    <Text>The badges achieved by the children at Carr Manor Community School.</Text>
    </View>
    </View> */}





  </View>


//   )}

//   else {
//     return(

// <ScrollView>
//   <HousePointsWeekly />
// </ScrollView>)
//     }
  

  // ;
)}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    // backgroundColor: 'transparent',
    // width: '100%',
    // alignSelf: 'center',
  },
  
});






