import { StyleSheet, Image, Pressable} from "react-native";
import React from "react";
import {useNavigation} from '@react-navigation/native';
import { IShoutOut } from "../../models/types";

interface ShoutOutProps  {
  shoutout: IShoutOut
}

const ThumbnailShoutOut = ({shoutout}: ShoutOutProps) => {
  

  const navigation = useNavigation();

  const NavigateToShoutOutsDetail = () => {
    
    navigation.navigate('ShoutOutsDetail', {shoutOutID: shoutout.id}) // TYPESCRIPT ERROR. Also, you shouldn't be sending the whole object through navigatin, just the ID of the shoutout then query the database on the detail screen
  }

  return <Pressable onPress={NavigateToShoutOutsDetail}><Image source={{ uri: shoutout.image }} style={styles.image} /></Pressable>;
};

export default ThumbnailShoutOut;

const styles = StyleSheet.create({
  image: {
    width: 230,
    height: 230,
    borderRadius: 5,
    margin: 10,
  },
});
