import { View, Text } from "../../constants/Themed";
import { StyleSheet, Image, Pressable } from "react-native";
import React from "react";
import { IUser } from "../../models/types";
import { useNavigation } from "@react-navigation/native";
import { BoldText } from "../../constants/StyledText";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";

interface ITaggedProps {
  tagged: IUser;
}

const TaggedTimeline = ({ tagged }: ITaggedProps) => {
  const navigation = useNavigation();
  const colorScheme = useColorScheme();
  const NavigateToTimelineDetail = () => {
    // const userIDForQuery = tagged.id;
    navigation.navigate("TimelinesDetail", { userIDForQuery: tagged.id });
  };

  return (
    <Pressable onPress={NavigateToTimelineDetail}>
      <View style={styles.taggedItemContainer}>
        <Image
          source={{
            uri: tagged.image,
          }}
          style={styles.taggedProfileImage}
        />
        <BoldText
          style={[
            styles.taggedName,
            { color: Colors[colorScheme].textLessEmphasised },
          ]}
        >
          {tagged.name}
        </BoldText>
      </View>
    </Pressable>
  );
};

export default TaggedTimeline;

const styles = StyleSheet.create({
  taggedProfileImage: {
    width: 35,
    height: 35,
    borderRadius: 20,
    marginBottom: 5,
  },
  taggedItemContainer: {
    alignItems: "center",
    margin: 5,
    width: 55,
    backgroundColor: "transparent",
  },
  taggedName: {
    fontSize: 8,
    flexWrap: "wrap",
    textAlign: "center",
  },
});
