import {
  StyleSheet,
  Image,
  ActivityIndicator,
  useWindowDimensions,
  ScrollView,
  TextInput,
  FlatList,
  Pressable,
  Switch,
} from "react-native";
import React, { useState, useEffect } from "react";
import { BoldText } from "../constants/StyledText";
import { Text, View } from "../constants/Themed";
import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import { API, graphqlOperation } from "aws-amplify";
import SearchBarMobile from "./mobile/SearchBar";
import ThumbnailTimeline from "./ThumbnailTimeline";
import { Feather } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";

//note, you have removed the line about limit from the top of this query (where it gets defined as an int) and added a limit of 2000. If you don't do this, you don't get all of the results!
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $nextToken: String) {
    listUsers(filter: $filter, limit: 9999, nextToken: $nextToken) {
      items {
        id
        surname
        aaa
        preferredFirstName
        type
        photoURL
        isActive
        Enrolments {
          items {
            groupingData1
            groupingData2
            groupingData3
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const createAwardedBadge = /* GraphQL */ `
  mutation CreateAwardedBadge(
    $input: CreateAwardedBadgeInput!
    $condition: ModelAwardedBadgeConditionInput
  ) {
    createAwardedBadge(input: $input, condition: $condition) {
      id
      dateAwarded
      scoringData1Value
      scoringData2Value
      scoringData3Value
      groupingData1Value
      groupingData2Value
      groupingData3Value
      opportunityID

      userID

      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createMapping = /* GraphQL */ `
  mutation CreateMapping(
    $input: CreateMappingInput!
    $condition: ModelMappingConditionInput
  ) {
    createMapping(input: $input, condition: $condition) {
      id
      level
      criteriaID
      opportunityID

      isActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createOpportunity = /* GraphQL */ `
  mutation CreateOpportunity(
    $input: CreateOpportunityInput!
    $condition: ModelOpportunityConditionInput
  ) {
    createOpportunity(input: $input, condition: $condition) {
      id
      isActive
      name
      aaa
      description
      logoURL
      location
      colour
      engagementLevel
      regularMonday
      regularTuesday
      regularWednesday
      regularThursday
      regularFriday
      regularStartTime
      regularEndTime
      dateType
      oneOffDate
      regularStartDat
      regularEndDate
      multipleStartDate
      multipleEndDate
      totalHours
      userID
      opportunitycategoryID
      availableTo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
const NewOpportunityForm = ({ navigation }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [clickedUsers, setClickedUsers] = useState(false);
  const [searchUsers, setSearchUsers] = useState("");
  const [search, setSearch] = useState("");
  const [badgeName, setBadgeName] = useState('');
  const [badgeDescription, setBadgeDescription] = useState(null);
  const colorScheme = useColorScheme();
  const [userArrayJustStaff, setUserArrayJustStaff] = useState([]);
  const [filteredDataUsers, setFilteredDataUsers] = useState([]);
  const opportunityPrefix = "CARRMANOR-OPP-";
  const randomNumber = Math.floor(Math.random() * 10000000);
  const today = new Date().toISOString().slice(0, 10);
  const [opportunityID, setOpportunityID] = useState(
    opportunityPrefix.concat(Date.now(), "-", randomNumber)
  );


  const [uploadApproveName, setUploadApproveName] = useState(false)
  const [uploadApproveDesc, setUploadApproveDesc] = useState(false)
  const [uploadApproveStaff, setUploadApproveStaff] = useState(false)
  const [uploadApproveCat, setUploadApproveCat] = useState(false)
  const [uploadApporveHours, setUploadApproveHours] = useState(false)
  const [uploadApproveDateType, setUploadApproveDateType] = useState(false)

  useEffect(() => {
    fetchUsersList();
  }, []);

  const fetchUsersList = async () => {
    const response = await API.graphql(
      graphqlOperation(listUsers, {
        filter: {
          type: {
            ne: "ALUMNI",
            // eq: "CHILD"
          },
        },
      })
    );

    setFilteredData(response.data.listUsers.items);
    setMasterData(response.data.listUsers.items);
    setUserArrayJustStaff(
      response.data.listUsers.items.filter((item) => item.type === "STAFF" || item.type === "ADMIN")
    );
  };
  const [processingUploadData, setProcessingUploadData] = useState(false);
  const [staffLeader, setStaffLeader] = useState([]);
  const [usersToBadge, setUsersToBadge] = useState([]);
  const [location, setLocation] = useState("");
  const [totalHours, setTotalHours] = useState(1);
  const [category, setCategory] = useState("");
  const [engagementLevel, setEngagementLevel] = useState(0);
  const [dateType, setDateType] = useState("");
  const [oneOffDateDay, setOneOffDateDay] = useState("");
  const [oneOffDateMonth, setOneOffDateMonth] = useState("");
  const [oneOffDateYear, setOneOffDateYear] = useState("");
  const [oneOffDate, setOneOffDate] = useState("");
  const [housePoints, setHousePoints] = useState(1);
  const [multipleStartDate, setMultipleStartDate] = useState("");
  const [multipleEndDate, setMultipleEndDate] = useState("");
  const [multipleStartDateDay, setMultipleStartDateDay] = useState("");
  const [multipleStartDateMonth, setMultipleStartDateMonth] = useState("");
  const [multipleStartDateYear, setMultipleStartDateYear] = useState("");
  const [multipleEndDateDay, setMultipleEndDateDay] = useState("");
  const [multipleEndDateMonth, setMultipleEndDateMonth] = useState("");
  const [multipleEndDateYear, setMultipleEndDateYear] = useState("");
const [triggerWarning, setTriggerWarning] = useState(false);
  const [logo, setLogo] = useState("public/generic-assets/icon.png");
  const [colorToMatchLogo, setColorToMatchLogo] = useState("");

  const [stopCode, setStopCode] = useState(false);

  const [regularStartDate, setRegularStartDate] = useState("");
  const [regularEndDate, setRegularEndDate] = useState("");
  const [regularStartDateDay, setRegularStartDateDay] = useState("");
  const [regularStartDateMonth, setRegularStartDateMonth] = useState("");
  const [regularStartDateYear, setRegularStartDateYear] = useState("");
  const [regularEndDateDay, setRegularEndDateDay] = useState("");
  const [regularEndDateMonth, setRegularEndDateMonth] = useState("");
  const [regularEndDateYear, setRegularEndDateYear] = useState("");

  const [isMondaySwitchEnabled, setIsMondaySwitchEnabled] = useState(false);
  const toggleMondaySwitch = () =>
    setIsMondaySwitchEnabled((previousState) => !previousState);
  const [staffName, setStaffName] = useState("");
  const [isTuesdaySwitchEnabled, setIsTuesdaySwitchEnabled] = useState(false);
  const toggleTuesdaySwitch = () =>
    setIsTuesdaySwitchEnabled((previousState) => !previousState);
  const [isWednesdaySwitchEnabled, setIsWednesdaySwitchEnabled] =
    useState(false);
  const toggleWednesdaySwitch = () =>
    setIsWednesdaySwitchEnabled((previousState) => !previousState);

  const [isThursdaySwitchEnabled, setIsThursdaySwitchEnabled] = useState(false);
  const toggleThursdaySwitch = () =>
    setIsThursdaySwitchEnabled((previousState) => !previousState);

  const [isFridaySwitchEnabled, setIsFridaySwitchEnabled] = useState(false);
  const toggleFridaySwitch = () =>
    setIsFridaySwitchEnabled((previousState) => !previousState);

  const [isSaturdaySwitchEnabled, setIsSaturdaySwitchEnabled] = useState(false);
  const toggleSaturdaySwitch = () =>
    setIsSaturdaySwitchEnabled((previousState) => !previousState);

  const [isSundaySwitchEnabled, setIsSundaySwitchEnabled] = useState(false);
  const toggleSundaySwitch = () =>
    setIsSundaySwitchEnabled((previousState) => !previousState);

  const [isCollaborationSwitchEnabled, setIsCollaborationSwitchEnabled] =
    useState(false);
  const toggleCollaborationSwitch = () =>
    setIsCollaborationSwitchEnabled((previousState) => !previousState);

  const [isInfluenceSwitchEnabled, setIsInfluenceSwitchEnabled] =
    useState(false);
  const toggleInfluenceSwitch = () =>
    setIsInfluenceSwitchEnabled((previousState) => !previousState);

  const [isCommunicationSwitchEnabled, setIsCommunicationSwitchEnabled] =
    useState(false);
  const toggleCommunicationSwitch = () =>
    setIsCommunicationSwitchEnabled((previousState) => !previousState);

  const [isInitiativeSwitchEnabled, setIsInitiativeSwitchEnabled] =
    useState(false);
  const toggleInitiativeSwitch = () =>
    setIsInitiativeSwitchEnabled((previousState) => !previousState);

  const [isCriticalThinkingSwitchEnabled, setIsCriticalThinkingSwitchEnabled] =
    useState(false);
  const toggleCriticalThinkingSwitch = () =>
    setIsCriticalThinkingSwitchEnabled((previousState) => !previousState);

  const [isAmbitionSwitchEnabled, setIsAmbitionSwitchEnabled] = useState(false);
  const toggleAmbitionSwitch = () =>
    setIsAmbitionSwitchEnabled((previousState) => !previousState);

  const [isSelfAwarenessSwitchEnabled, setIsSelfAwarenessSwitchEnabled] =
    useState(false);
  const toggleSelfAwarenessSwitch = () =>
    setIsSelfAwarenessSwitchEnabled((previousState) => !previousState);

  const [isCommitmentSwitchEnabled, setIsCommitmentSwitchEnabled] =
    useState(false);
  const toggleCommitmentSwitch = () =>
    setIsCommitmentSwitchEnabled((previousState) => !previousState);

  const [isCourageSwitchEnabled, setIsCourageSwitchEnabled] = useState(false);
  const toggleCourageSwitch = () =>
    setIsCourageSwitchEnabled((previousState) => !previousState);
  const [isInclusivenessSwitchEnabled, setIsInclusivenessSwitchEnabled] =
    useState(false);
  const toggleInclusivenessSwitch = () =>
    setIsInclusivenessSwitchEnabled((previousState) => !previousState);

  const [isIntegritySwitchEnabled, setIsIntegritySwitchEnabled] =
    useState(false);
  const toggleIntegritySwitch = () =>
    setIsIntegritySwitchEnabled((previousState) => !previousState);

  const [isCompassionSwitchEnabled, setIsCompassionSwitchEnabled] =
    useState(false);
  const toggleCompassionSwitch = () =>
    setIsCompassionSwitchEnabled((previousState) => !previousState);

  //combine the one off dates into a single string when any of them change
  useEffect(() => {
    setOneOffDate(
      oneOffDateYear.concat("-", oneOffDateMonth, "-", oneOffDateDay)
    );
  }, [oneOffDateDay, oneOffDateMonth, oneOffDateYear]);

  //combine the multiple start dates into a single string when any of them change
  useEffect(() => {
    setMultipleStartDate(
      multipleStartDateYear.concat(
        "-",
        multipleStartDateMonth,
        "-",
        multipleStartDateDay
      )
    );
  }, [multipleStartDateDay, multipleStartDateMonth, multipleStartDateYear]);

  //combine the multiple start dates into a single string when any of them change
  useEffect(() => {
    setMultipleEndDate(
      multipleEndDateYear.concat(
        "-",
        multipleEndDateMonth,
        "-",
        multipleEndDateDay
      )
    );
  }, [multipleEndDateDay, multipleEndDateMonth, multipleEndDateYear]);

  //combine the regular start dates into a single string when any of them change
  useEffect(() => {
    setRegularStartDate(
      regularStartDateYear.concat(
        "-",
        regularStartDateMonth,
        "-",
        regularStartDateDay
      )
    );
  }, [regularStartDateDay, regularStartDateMonth, regularStartDateYear]);

  //combine the regular start dates into a single string when any of them change
  useEffect(() => {
    setRegularEndDate(
      regularEndDateYear.concat(
        "-",
        regularEndDateMonth,
        "-",
        regularEndDateDay
      )
    );
  }, [regularEndDateDay, regularEndDateMonth, regularEndDateYear]);

  useEffect(() => {
    switch (category) {
      case "37dbe22c-c4e2-4f8b-b9ee-939279e56487": //ambassador
        setLogo(
          "organisation-assets/carrmanor/opportunityCategoryLogos/Ambassador_badge.png"
        );
        setColorToMatchLogo("#91d4d4");
        break;
      case "9b07f29f-9188-492a-9536-09ed59b3f224": //flexible learning
        setLogo(
          "organisation-assets/carrmanor/opportunityCategoryLogos/FlexibleLearning_badge.png"
        );
        setColorToMatchLogo("#68acf1");
        break;
      case "384e5604-4516-49c7-9b58-8f02f1ea0ba0": //work experience
        setLogo(
          "organisation-assets/carrmanor/opportunityCategoryLogos/WorkExperience_badge.png"
        );
        setColorToMatchLogo("#ec5074");
        break;
      case "b7da20c0-9e36-4c70-9cbf-651080193040": //volunteering
        setLogo(
          "organisation-assets/carrmanor/opportunityCategoryLogos/Volunteering_badge.png"
        );
        setColorToMatchLogo("#c5db46");
        break;
      case "71ee8a14-7ab4-45c4-827c-27064b2417e0": //visits
        setLogo(
          "organisation-assets/carrmanor/opportunityCategoryLogos/Visits_badge.png"
        );
        setColorToMatchLogo("#f39f38");
        break;
      case "0aeacce3-6316-4eb6-9ab1-fba086f30ce8": //independent learning
        setLogo(
          "organisation-assets/carrmanor/opportunityCategoryLogos/IndependentLearning_badge.png"
        );
        setColorToMatchLogo("#9087bd");
        break;
      default:
        setLogo("public/generic-assets/icon.png");
        setColorToMatchLogo("#000000");
    }
  }, [category]);

  const removeFromArray = (id) => {
    setSearch("");
    setStaffLeader((user) =>
      user.filter((obj) => {
        return obj.id !== id;
      })
    );
  };

  const removeFromUsersArray = (id) => {
    setSearchUsers("");
    setUsersToBadge((user) =>
      user.filter((obj) => {
        return obj.id !== id;
      })
    );
  };

  const addToUsersArray = (item) => {
    if (!usersToBadge.some((u) => u.id === item.id)) {
      //if the user isn't already in the array
      setSearchUsers(""); //reset the search box
      setUsersToBadge((oldArray) => [...oldArray, item]); //then add the user to the end of the array
    } else {
      alert("You can't add the same user twice!");
    }
  };

  const addToArray = (item) => {
    setStaffLeader((oldArray) => [...oldArray, item]);
  };

  const submitRecords = () => {
    setStopCode(false);
    setTriggerWarning(false);
    setProcessingUploadData(true);
    setStopCode(false)
    setUploadApproveName(false)
setUploadApproveDesc(false)
setUploadApproveStaff(false)
setUploadApproveCat(false)
setUploadApproveHours(false)
setUploadApproveDateType(false)
var uploadApproveNameNullVariable: boolean = false
var uploadApproveNameBlankVariable: boolean = false
var uploadApproveDescVariable: boolean = false
var uploadApproveCatNullVariable: boolean = false
var uploadApproveCatBlankVariable: boolean = false
var uploadApproveHoursVariable: boolean = false
var uploadApproveDateTypeNullVariable: boolean = false
var uploadApproveDateTypeBlankVariable: boolean = false
var uploadApproveStaffNullVariable: boolean = false

    
    //run a series of checks

    const writeOpportunityToDatabase = async () => {
      console.warn("uploading this:", opportunityDetailsToUpload);
      await API.graphql({
        query: createOpportunity,
        variables: { input: opportunityDetailsToUpload },
      });
      console.warn("async write function for opporutnity table called");
    };
    const writeMappingToDatabase = async (whichOpportunity, whichCriteria) => {
      const mappingDetailsToUpload = {
        opportunityID: whichOpportunity,
        criteriaID: whichCriteria,
        level: 1,
        isActive: true,
      };

      await API.graphql({
        query: createMapping,
        variables: { input: mappingDetailsToUpload },
      });
      console.warn("async write function for mapping table called");
      console.warn("mappingdetailstoupload:", mappingDetailsToUpload);
    };

    const writeAwardedBadgeToDatabase = async (awardedBadgeDetailsToUpload) => {
      await API.graphql({
        query: createAwardedBadge,
        variables: { input: awardedBadgeDetailsToUpload },
      });
      console.warn("async write function for mapping table called");
      console.warn("awarded badge to upload:", awardedBadgeDetailsToUpload);
    };

    //prepare object for opportunity as an object
    const opportunityDetailsToUpload = {
      id: opportunityID,
      isActive: false,
      name: badgeName,
      aaa: badgeName,
      description: badgeDescription,
      logoURL: logo,
      location: location,
      colour: colorToMatchLogo,
      engagementLevel: engagementLevel,
      regularMonday: isMondaySwitchEnabled,
      regularTuesday: isTuesdaySwitchEnabled,
      regularWednesday: isWednesdaySwitchEnabled,
      regularThursday: isThursdaySwitchEnabled,
      regularFriday: isFridaySwitchEnabled,
      dateType: dateType,
      oneOffDate: oneOffDate.length === 10 ? oneOffDate : null,
      regularStartDat: regularStartDate.length === 10 ? regularStartDate : null,
      regularEndDate: regularEndDate.length === 10 ? regularEndDate : null,
      multipleStartDate:
        multipleStartDate.length === 10 ? multipleStartDate : null,
      multipleEndDate: multipleEndDate.length === 10 ? multipleEndDate : null,
      totalHours: totalHours ? (totalHours > 0 ? totalHours : 1) : 1,
      userID: staffLeader[0]?.id,
      opportunitycategoryID: category,
    };

    if (opportunityDetailsToUpload.id === null) {
      setStopCode(true);
      console.warn("ERROR: No ID. Contact Laycock");
      alert("ERROR: No ID. Contact Laycock");
    
    } else {
      setStopCode(false);
      // uploadApproveIDVariable = true 
      // console.warn('id:', uploadApproveIDVariable)
    }

    if (opportunityDetailsToUpload.name === null) {
      setUploadApproveName(false);
      console.warn("ERROR: Are you sure you added a name for this badge?");
      alert("ERROR: Are you sure you added a name for this badge?");
    } else {
      console.warn('else name not null fired')
      setUploadApproveName(true);
      uploadApproveNameNullVariable = true 
      console.warn(uploadApproveName)
      console.warn('name not null:', uploadApproveNameNullVariable)
    }

    if (opportunityDetailsToUpload.name === "") {
      setUploadApproveName(false);
      console.warn("ERROR: Are you sure you added a name for this badge?");
      alert("ERROR: Are you sure you added a name for this badge?");
    } else {
      console.warn('else name not "" fired')
      setUploadApproveName(true);
      console.warn(uploadApproveName)
      uploadApproveNameBlankVariable = true 
      console.warn('name not "":', uploadApproveNameBlankVariable)
    }

    if (opportunityDetailsToUpload.description === null) {
      setUploadApproveDesc(false);
      console.warn(
        "ERROR: Are you sure you added a description for this badge?"
      );
      alert("ERROR: Are you sure you added a description for this badge?");
    } else {
      setUploadApproveDesc(true);
      uploadApproveDescVariable = true 
      console.warn('desc:', uploadApproveDescVariable)
    }

    if (opportunityDetailsToUpload.userID === undefined) {
      setUploadApproveStaff(false);
      console.warn(
        "ERROR: Are you sure you added a staff member for this badge?"
      );
      alert("ERROR: Are you sure you added a staff member for this badge?");
    } else {
      setUploadApproveStaff(true);
      uploadApproveStaffNullVariable = true 
      console.warn('staff:', uploadApproveStaffNullVariable)
    }

    if (opportunityDetailsToUpload.totalHours === null) {
      setUploadApproveHours(false);
      console.warn(
        "ERROR: Are you sure you added the total hours for this badge?"
      );
      alert("ERROR: Are you sure you added the total hours for this badge?");
    } else {
      setUploadApproveHours(true);
      uploadApproveHoursVariable = true 
      console.warn('hours:', uploadApproveHoursVariable)
    }

    if (opportunityDetailsToUpload.opportunitycategoryID === null) {
      setUploadApproveCat(false);
      console.warn(
        "ERROR: Are you sure you added the category for this badge?"
      );
      alert("ERROR: Are you sure you added the category for this badge?");
    } else {
      setUploadApproveCat(true);
      uploadApproveCatNullVariable = true 
      console.warn('cat:', uploadApproveCatNullVariable)
    }

    if (opportunityDetailsToUpload.opportunitycategoryID === "") {
      setUploadApproveCat(false);
      console.warn(
        "ERROR: Are you sure you added the category for this badge?"
      );
      alert("ERROR: Are you sure you added the category for this badge?");
    } else {
      setUploadApproveCat(true);
      uploadApproveCatBlankVariable = true 
      console.warn('cat:', uploadApproveCatBlankVariable)
    }

    if (opportunityDetailsToUpload.dateType === null) {
      setUploadApproveDateType(false);
      console.warn(
        "ERROR: Are you sure you added the date type for this badge?"
      );
      alert("ERROR: Are you sure you added the date type for this badge?");
    } else {
      setUploadApproveDateType(true);
      uploadApproveDateTypeNullVariable = true 
      console.warn('datetype:', uploadApproveDateTypeNullVariable)
    }

    if (opportunityDetailsToUpload.dateType === "") {
      setUploadApproveDateType(false);
      console.warn(
        "ERROR: Are you sure you added the date type for this badge?"
      );
      alert("ERROR: Are you sure you added the date type for this badge?");
    } else {
      setUploadApproveDateType(true);
      uploadApproveDateTypeBlankVariable = true 
      console.warn('datetype:', uploadApproveDateTypeBlankVariable)
    }

    //final check of stop code before upload
    if (
      // stopCode === false
      //   && uploadApproveName === true
      //   && uploadApproveDesc === true
      //   && uploadApproveCat === true
      //   && uploadApporveHours === true
      //   && uploadApproveDateType === true
      //   && uploadApproveStaff === true
      uploadApproveNameNullVariable == true 
      && uploadApproveNameBlankVariable == true 
       &&  uploadApproveDescVariable == true
        && uploadApproveCatNullVariable == true
        && uploadApproveCatBlankVariable == true
        && uploadApproveHoursVariable == true
        && uploadApproveStaffNullVariable == true
        && uploadApproveDateTypeNullVariable == true 
        && uploadApproveDateTypeBlankVariable == true 




      ) {
        setTriggerWarning(false)
      console.warn(
        "ready to upload this:",
        "to opportunity table",
        opportunityDetailsToUpload
      );

      writeOpportunityToDatabase();

      //prepare array of objects for mappings

      if (isInitiativeSwitchEnabled) {
        writeMappingToDatabase(opportunityID, "lca12");
      }

      if (isCourageSwitchEnabled) {
        writeMappingToDatabase(opportunityID, "lca08");
      }

      if (isIntegritySwitchEnabled) {
        writeMappingToDatabase(opportunityID, "lca01");
      }

      if (isCriticalThinkingSwitchEnabled) {
        writeMappingToDatabase(opportunityID, "lca11");
      }

      if (isCompassionSwitchEnabled) {
        writeMappingToDatabase(opportunityID, "lca03");
      }

      if (isInclusivenessSwitchEnabled) {
        writeMappingToDatabase(opportunityID, "lca02");
      }

      if (isAmbitionSwitchEnabled) {
        writeMappingToDatabase(opportunityID, "lca10");
      }

      if (isSelfAwarenessSwitchEnabled) {
        writeMappingToDatabase(opportunityID, "lca09");
      }
      if (isCommunicationSwitchEnabled) {
        writeMappingToDatabase(opportunityID, "lca06");
      }
      if (isInfluenceSwitchEnabled) {
        writeMappingToDatabase(opportunityID, "lca04");
      }
      if (isCollaborationSwitchEnabled) {
        writeMappingToDatabase(opportunityID, "lca05");
      }
      if (isCommitmentSwitchEnabled) {
        writeMappingToDatabase(opportunityID, "lca07");
      }

      console.warn("USERS TO BADGE:", usersToBadge);

      const addAwardedBadgesToDatabase = (id) => {
        function getCoach(id) {
          //get the coach using the child's ID. If not a child account, don't even look for an enrolement
          const kid = masterData.filter((obj) => {
            return obj.id === id;
          });

          if (kid[0].type === "CHILD") {
            return kid[0].Enrolments.items[0].groupingData2;
          } else {
            return null;
          }
        }

        function getYear(id) {
          //get the Year using the child's ID. If not a child account, don't even look for an enrolement
          const kid = masterData.filter((obj) => {
            return obj.id === id;
          });

          if (kid[0].type === "CHILD") {
            return kid[0].Enrolments.items[0].groupingData3;
          } else {
            return null;
          }
        }

        function getHouse(id) {
          //get the Year using the child's ID. If not a child account, don't even look for an enrolement
          const kid = masterData.filter((obj) => {
            return obj.id === id;
          });

          if (kid[0].type === "CHILD") {
            return kid[0].Enrolments.items[0].groupingData1;
          } else {
            return null;
          }
        }

        //prepare array of objects for awardedBadges for each user
        const awardedBadgeDetailsToUpload = {
          dateAwarded: today,
          groupingData1Value: getHouse(id),
          groupingData2Value: getCoach(id),
          groupingData3Value: getYear(id),
          opportunityID: opportunityID,
          userID: id,
          scoringData3Value: housePoints,
        };

        writeAwardedBadgeToDatabase(awardedBadgeDetailsToUpload);
      };

      usersToBadge.forEach((row) => addAwardedBadgesToDatabase(row.id));

      navigation.navigate("AdminBadgeConfirmation");
    } else {
      setTriggerWarning(true)
      console.warn("you have errors the upload was cancelled");
      // console.warn('triggerwarning=', triggerWarning)
      // console.warn('stopcode=', stopCode)
      console.warn('name:', uploadApproveName, opportunityDetailsToUpload.name)
      console.warn('desc:', uploadApproveDesc, opportunityDetailsToUpload.description)
      console.warn('staffID:', uploadApproveStaff, opportunityDetailsToUpload.userID)
      console.warn('catID:', uploadApproveCat, opportunityDetailsToUpload.opportunitycategoryID)
      console.warn('hours:', uploadApporveHours, opportunityDetailsToUpload.totalHours)
      console.warn('datetype:',uploadApproveDateType, opportunityDetailsToUpload.dateType )
      console.warn('triggerwarning=', triggerWarning)
      console.warn('stopcode=', stopCode)
      console.warn('nameN:', uploadApproveNameNullVariable, opportunityDetailsToUpload.name)
      console.warn('nameB:', uploadApproveNameBlankVariable, opportunityDetailsToUpload.name)
      console.warn('desc:', uploadApproveDescVariable, opportunityDetailsToUpload.description)
      console.warn('staffID:', uploadApproveStaffNullVariable, opportunityDetailsToUpload.userID)
      console.warn('catIDN:', uploadApproveCatNullVariable, opportunityDetailsToUpload.opportunitycategoryID)
      console.warn('catIDB:', uploadApproveCatBlankVariable, opportunityDetailsToUpload.opportunitycategoryID)
      console.warn('hours:', uploadApproveHoursVariable, opportunityDetailsToUpload.totalHours)
      console.warn('datetypeN:',uploadApproveDateTypeNullVariable, opportunityDetailsToUpload.dateType )
      console.warn('datetypeB:',uploadApproveDateTypeBlankVariable, opportunityDetailsToUpload.dateType )

      return null;
    }
  };

  const searchFilter = (text) => {
    if (text) {
      const newData = userArrayJustStaff.filter((item) => {
        const itemData = item.aaa ? item.aaa.toUpperCase() : "".toLowerCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });

      setFilteredData(newData);
      setSearch(text);
    } else {
      setFilteredData(userArrayJustStaff);
      setSearch(text);
    }
  };

  const searchFilterUsers = (text) => {
    if (text) {
      const newData = masterData.filter((item) => {
        const itemData = item.aaa ? item.aaa.toUpperCase() : "".toLowerCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });

      setFilteredDataUsers(newData);
      setSearchUsers(text);
    } else {
      setFilteredDataUsers(masterData);
      setSearchUsers(text);
    }
  };

  return (
    <ScrollView contentContainerStyle={{ width: 900, alignSelf: 'center', alignItems:'center', backgroundColor:'transparent' }}>
      {/* <Text>Items held in state ready to write to database</Text>
      <Text>Name of badge: {badgeName}</Text>
      <Text>Description: {badgeDescription}</Text>
      <Text>Date type: {dateType}</Text>
      <Text>One Off date: {oneOffDate}</Text>
      <Text>mutltiple start date: {multipleStartDate}</Text>
      <Text>mutltiple end date: {multipleEndDate}</Text>
      <Text>staff name: {staffName}</Text>
      <Text>Monday: {isMondaySwitchEnabled === true ? "yes" : "no"}</Text>
      <Text>Tuesday: {isTuesdaySwitchEnabled === true ? "yes" : "no"}</Text>
      <Text>Wednesday: {isWednesdaySwitchEnabled === true ? "yes" : "no"}</Text>
      <Text>Thursday: {isThursdaySwitchEnabled === true ? "yes" : "no"}</Text>
      <Text>Friday: {isFridaySwitchEnabled === true ? "yes" : "no"}</Text>
      <Text>Saturday: {isSaturdaySwitchEnabled === true ? "yes" : "no"}</Text>
      <Text>Sunday: {isSundaySwitchEnabled === true ? "yes" : "no"}</Text>
      <Text>ID: {opportunityID}</Text> */}

      {/* {usersToBadge.map((user) => {
        //Item in the list of users to add the badge to
        return (
          <View
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
              backgroundColor: Colors[colorScheme].background,
            }}
          >
            <Text style={{ color: Colors[colorScheme].text, fontSize: 14 }}>
              {user.surname} {user.preferredFirstName}
            </Text>
            <Pressable onPress={() => removeFromArray(user.id)}>
              <Feather name="trash-2" size={20} color="red" />
            </Pressable>
          </View>
        );
      })} */}

      {/* BADGE NAME  */}
      <View style={styles.textInputContainer}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={badgeName ? Colors.palette.logoGreen : "transparent"}
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Badge name:
          </Text>
        </View>
        <TextInput
          style={[
            styles.textInputBox,
            {
              outlineStyle: "none",
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
          onChangeText={setBadgeName}
          value={badgeName}
          maxLength={50}
        />
      </View>

      {/* BADGE DESCRIPTION  */}
      <View style={styles.textInputContainer}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={badgeDescription ? Colors.palette.logoGreen : "transparent"}
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Description:
          </Text>
        </View>
        <TextInput
          style={[
            styles.textInputBox,
            {
              outlineStyle: "none",
              height: 70,
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
          onChangeText={setBadgeDescription}
          maxLength={300}
          multiline
        />
      </View>

      {/* LOCATION  */}
      <View style={styles.textInputContainer}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={location ? Colors.palette.logoGreen : "transparent"}
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Location:
          </Text>
        </View>
        <TextInput
          style={[
            styles.textInputBox,
            {
              outlineStyle: "none",
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
          onChangeText={setLocation}
          maxLength={50}
        />
      </View>

      {/* TOTAL HOURS  */}
      <View style={styles.textInputContainer}>
        <View style={styles.textInputLabelAndIconContainer}>
          {totalHours ? (
            isNaN(totalHours) ? (
              <Feather
                name="alert-triangle"
                size={18}
                color={Colors.palette.softRed}
              />
            ) : (
              <Feather
                name="thumbs-up"
                size={18}
                color={Colors.palette.logoGreen}
              />
            )
          ) : (
            <Feather
              name="alert-triangle"
              size={18}
              color={Colors.palette.softRed}
            />
          )}
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Total hours:
          </Text>
        </View>
        <TextInput
          style={[
            styles.textInputBox,
            {
              outlineStyle: "none",
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
          onChangeText={setTotalHours}
          maxLength={3}
          value={totalHours}
        />
      </View>

      {/* HOUSE POINTS  */}
      <View style={styles.textInputContainer}>
        <View style={styles.textInputLabelAndIconContainer}>
          {housePoints ? (
            isNaN(housePoints) ? (
              <Feather
                name="alert-triangle"
                size={18}
                color={Colors.palette.softRed}
              />
            ) : (
              <Feather
                name="thumbs-up"
                size={18}
                color={Colors.palette.logoGreen}
              />
            )
          ) : (
            <Feather
              name="alert-triangle"
              size={18}
              color={Colors.palette.softRed}
            />
          )}
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            House Points:
          </Text>
        </View>
        <TextInput
          style={[
            styles.textInputBox,
            {
              outlineStyle: "none",
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
          onChangeText={setHousePoints}
          maxLength={3}
          value={housePoints}
        />
      </View>

      {/* CATEGORY  */}
      <View style={styles.textInputContainer}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={category ? Colors.palette.logoGreen : "transparent"}
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Category:
          </Text>
        </View>
        <Picker
          style={[
            styles.textInputBox,
            {
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
          // itemStyle={{fontFamily: 'avenir-next', color: 'red'}}
          selectedValue={category}
          onValueChange={(itemValue, itemIndex) => setCategory(itemValue)}
        >
          <Picker.Item label="Select category" value="" />
          <Picker.Item
            label="Ambassador"
            value="37dbe22c-c4e2-4f8b-b9ee-939279e56487"
          />
          <Picker.Item
            label="Work experience"
            value="384e5604-4516-49c7-9b58-8f02f1ea0ba0"
          />
          <Picker.Item
            label="Flexible Learning"
            value="9b07f29f-9188-492a-9536-09ed59b3f224"
          />
          <Picker.Item
            label="Volunteering"
            value="b7da20c0-9e36-4c70-9cbf-651080193040"
          />
          <Picker.Item
            label="Visits"
            value="71ee8a14-7ab4-45c4-827c-27064b2417e0"
          />
          <Picker.Item
            label="Independent Learning"
            value="0aeacce3-6316-4eb6-9ab1-fba086f30ce8"
          />
        </Picker>
      </View>

      {/* ENGAGEMENT LEVEL  */}
      <View style={styles.textInputContainer}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={engagementLevel ? Colors.palette.logoGreen : "transparent"}
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Engagement level:
          </Text>
        </View>
        <Picker
          style={[
            styles.textInputBox,
            {
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
          // itemStyle={{fontFamily: 'avenir-next', color: 'red'}}
          selectedValue={engagementLevel}
          onValueChange={(itemValue, itemIndex) =>
            setEngagementLevel(itemValue)
          }
        >
          <Picker.Item label="Select engagement level" value="" />
          <Picker.Item label="1: School activity" value={1} />
          <Picker.Item
            label="2: Optional school activity during curriculum time"
            value={2}
          />
          <Picker.Item
            label="3: Optional school activity outside of curriculum time"
            value={3}
          />
          <Picker.Item
            label="4: Optional activity outside of school"
            value={4}
          />
        </Picker>
      </View>

      {/* DATE TYPE  */}
      <View style={styles.textInputContainer}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={dateType ? Colors.palette.logoGreen : "transparent"}
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Date type:
          </Text>
        </View>
        <Picker
          style={[
            styles.textInputBox,
            {
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
          // itemStyle={{fontFamily: 'avenir-next', color: 'red'}}
          selectedValue={dateType}
          onValueChange={(itemValue, itemIndex) => setDateType(itemValue)}
        >
          <Picker.Item label="Select date type" value="" />
          <Picker.Item label="One-off event" value="ONEOFFDATE" />
          <Picker.Item label="Multiple dates" value="MULTIPLEDATES" />
          <Picker.Item label="Regular dates" value="REGULAR" />
        </Picker>
      </View>

      {/* ONE OFF DATE */}
      {dateType === "ONEOFFDATE" ? (
        <View style={styles.textInputContainer}>
          <View style={styles.textInputLabelAndIconContainer}>
            <Feather
              name="thumbs-up"
              size={18}
              color={
                oneOffDate.length < 10
                  ? "transparent"
                  : Colors.palette.logoGreen
              }
            />
            <Text
              style={[
                { color: Colors[colorScheme].textLessEmphasised },
                styles.textInputLabel,
              ]}
            >
              Date:
            </Text>
          </View>
          <View
            style={{
              width: 315,
              flexDirection: "row",
              backgroundColor: "transparent",
            }}
          >
            <Picker
              style={[
                styles.textInputBox,
                {
                  color: Colors[colorScheme].textLessEmphasised,
                  backgroundColor: Colors[colorScheme].background,
                  borderColor: Colors[colorScheme].edging,
                },
              ]}
              // itemStyle={{fontFamily: 'avenir-next', color: 'red'}}
              selectedValue={oneOffDateDay}
              onValueChange={(itemValue, itemIndex) =>
                setOneOffDateDay(itemValue)
              }
            >
              <Picker.Item label="Day" value="" />
              <Picker.Item label="1" value="01" />
              <Picker.Item label="2" value="02" />
              <Picker.Item label="3" value="03" />
              <Picker.Item label="4" value="04" />
              <Picker.Item label="5" value="05" />
              <Picker.Item label="6" value="06" />
              <Picker.Item label="7" value="07" />
              <Picker.Item label="8" value="08" />
              <Picker.Item label="9" value="09" />
              <Picker.Item label="10" value="10" />
              <Picker.Item label="11" value="11" />
              <Picker.Item label="12" value="12" />
              <Picker.Item label="13" value="13" />
              <Picker.Item label="14" value="14" />
              <Picker.Item label="15" value="15" />
              <Picker.Item label="16" value="16" />
              <Picker.Item label="17" value="17" />
              <Picker.Item label="18" value="18" />
              <Picker.Item label="19" value="19" />
              <Picker.Item label="20" value="20" />
              <Picker.Item label="21" value="21" />
              <Picker.Item label="22" value="22" />
              <Picker.Item label="23" value="23" />
              <Picker.Item label="24" value="24" />
              <Picker.Item label="25" value="25" />
              <Picker.Item label="26" value="26" />
              <Picker.Item label="27" value="27" />
              <Picker.Item label="28" value="28" />
              <Picker.Item label="29" value="29" />
              <Picker.Item label="30" value="30" />
              <Picker.Item label="31" value="31" />
            </Picker>
            <Picker
              style={[
                styles.textInputBox,
                {
                  color: Colors[colorScheme].textLessEmphasised,
                  backgroundColor: Colors[colorScheme].background,
                  borderColor: Colors[colorScheme].edging,
                },
              ]}
              selectedValue={oneOffDateMonth}
              onValueChange={(itemValue, itemIndex) =>
                setOneOffDateMonth(itemValue)
              }
            >
              <Picker.Item label="Month" value="" />
              <Picker.Item label="Jan" value="01" />
              <Picker.Item label="Feb" value="02" />
              <Picker.Item label="Mar" value="03" />

              <Picker.Item label="Apr" value="04" />
              <Picker.Item label="May" value="05" />
              <Picker.Item label="Jun" value="06" />

              <Picker.Item label="Jul" value="07" />
              <Picker.Item label="Aug" value="08" />
              <Picker.Item label="Sep" value="09" />

              <Picker.Item label="Oct" value="10" />
              <Picker.Item label="Nov" value="11" />
              <Picker.Item label="Dec" value="12" />
            </Picker>
            <Picker
              style={[
                styles.textInputBox,
                {
                  color: Colors[colorScheme].textLessEmphasised,
                  backgroundColor: Colors[colorScheme].background,
                  borderColor: Colors[colorScheme].edging,
                },
              ]}
              selectedValue={oneOffDateYear}
              onValueChange={(itemValue, itemIndex) =>
                setOneOffDateYear(itemValue)
              }
            >
              <Picker.Item label="Year" value="" />
              <Picker.Item label="2021" value="2021" />
              <Picker.Item label="2022" value="2022" />
              <Picker.Item label="2023" value="2023" />
              <Picker.Item label="2024" value="2024" />
            </Picker>
          </View>
        </View>
      ) : null}

      {/* MULTIPLE DATES */}
      {dateType === "MULTIPLEDATES" ? (
        <>
          <View style={styles.textInputContainer}>
            <View style={styles.textInputLabelAndIconContainer}>
              <Feather
                name="thumbs-up"
                size={18}
                color={
                  multipleStartDate.length < 10
                    ? "transparent"
                    : Colors.palette.logoGreen
                }
              />
              <Text
                style={[
                  { color: Colors[colorScheme].textLessEmphasised },
                  styles.textInputLabel,
                ]}
              >
                Start date:
              </Text>
            </View>
            <View
              style={{
                width: 315,
                flexDirection: "row",
                backgroundColor: "transparent",
              }}
            >
              <Picker
                style={[
                  styles.textInputBox,
                  {
                    color: Colors[colorScheme].textLessEmphasised,
                    backgroundColor: Colors[colorScheme].background,
                    borderColor: Colors[colorScheme].edging,
                  },
                ]}
                // itemStyle={{fontFamily: 'avenir-next', color: 'red'}}
                selectedValue={multipleStartDateDay}
                onValueChange={(itemValue, itemIndex) =>
                  setMultipleStartDateDay(itemValue)
                }
              >
                <Picker.Item label="Day" value="" />
                <Picker.Item label="1" value="01" />
                <Picker.Item label="2" value="02" />
                <Picker.Item label="3" value="03" />
                <Picker.Item label="4" value="04" />
                <Picker.Item label="5" value="05" />
                <Picker.Item label="6" value="06" />
                <Picker.Item label="7" value="07" />
                <Picker.Item label="8" value="08" />
                <Picker.Item label="9" value="09" />
                <Picker.Item label="10" value="10" />
                <Picker.Item label="11" value="11" />
                <Picker.Item label="12" value="12" />
                <Picker.Item label="13" value="13" />
                <Picker.Item label="14" value="14" />
                <Picker.Item label="15" value="15" />
                <Picker.Item label="16" value="16" />
                <Picker.Item label="17" value="17" />
                <Picker.Item label="18" value="18" />
                <Picker.Item label="19" value="19" />
                <Picker.Item label="20" value="20" />
                <Picker.Item label="21" value="21" />
                <Picker.Item label="22" value="22" />
                <Picker.Item label="23" value="23" />
                <Picker.Item label="24" value="24" />
                <Picker.Item label="25" value="25" />
                <Picker.Item label="26" value="26" />
                <Picker.Item label="27" value="27" />
                <Picker.Item label="28" value="28" />
                <Picker.Item label="29" value="29" />
                <Picker.Item label="30" value="30" />
                <Picker.Item label="31" value="31" />
              </Picker>
              <Picker
                style={[
                  styles.textInputBox,
                  {
                    color: Colors[colorScheme].textLessEmphasised,
                    backgroundColor: Colors[colorScheme].background,
                    borderColor: Colors[colorScheme].edging,
                  },
                ]}
                selectedValue={multipleStartDateMonth}
                onValueChange={(itemValue, itemIndex) =>
                  setMultipleStartDateMonth(itemValue)
                }
              >
                <Picker.Item label="Month" value="" />
                <Picker.Item label="Jan" value="01" />
                <Picker.Item label="Feb" value="02" />
                <Picker.Item label="Mar" value="03" />

                <Picker.Item label="Apr" value="04" />
                <Picker.Item label="May" value="05" />
                <Picker.Item label="Jun" value="06" />

                <Picker.Item label="Jul" value="07" />
                <Picker.Item label="Aug" value="08" />
                <Picker.Item label="Sep" value="09" />

                <Picker.Item label="Oct" value="10" />
                <Picker.Item label="Nov" value="11" />
                <Picker.Item label="Dec" value="12" />
              </Picker>
              <Picker
                style={[
                  styles.textInputBox,
                  {
                    color: Colors[colorScheme].textLessEmphasised,
                    backgroundColor: Colors[colorScheme].background,
                    borderColor: Colors[colorScheme].edging,
                  },
                ]}
                selectedValue={multipleStartDateYear}
                onValueChange={(itemValue, itemIndex) =>
                  setMultipleStartDateYear(itemValue)
                }
              >
                <Picker.Item label="Year" value="" />
                <Picker.Item label="2021" value="2021" />
                <Picker.Item label="2022" value="2022" />
                <Picker.Item label="2023" value="2023" />
                <Picker.Item label="2024" value="2024" />
              </Picker>
            </View>
          </View>

          <View style={styles.textInputContainer}>
            <View style={styles.textInputLabelAndIconContainer}>
              <Feather
                name="thumbs-up"
                size={18}
                color={
                  multipleEndDate.length < 10
                    ? "transparent"
                    : Colors.palette.logoGreen
                }
              />
              <Text
                style={[
                  { color: Colors[colorScheme].textLessEmphasised },
                  styles.textInputLabel,
                ]}
              >
                End date:
              </Text>
            </View>
            <View
              style={{
                width: 315,
                flexDirection: "row",
                backgroundColor: "transparent",
              }}
            >
              <Picker
                style={[
                  styles.textInputBox,
                  {
                    color: Colors[colorScheme].textLessEmphasised,
                    backgroundColor: Colors[colorScheme].background,
                    borderColor: Colors[colorScheme].edging,
                  },
                ]}
                // itemStyle={{fontFamily: 'avenir-next', color: 'red'}}
                selectedValue={multipleEndDateDay}
                onValueChange={(itemValue, itemIndex) =>
                  setMultipleEndDateDay(itemValue)
                }
              >
                <Picker.Item label="Day" value="" />
                <Picker.Item label="1" value="01" />
                <Picker.Item label="2" value="02" />
                <Picker.Item label="3" value="03" />
                <Picker.Item label="4" value="04" />
                <Picker.Item label="5" value="05" />
                <Picker.Item label="6" value="06" />
                <Picker.Item label="7" value="07" />
                <Picker.Item label="8" value="08" />
                <Picker.Item label="9" value="09" />
                <Picker.Item label="10" value="10" />
                <Picker.Item label="11" value="11" />
                <Picker.Item label="12" value="12" />
                <Picker.Item label="13" value="13" />
                <Picker.Item label="14" value="14" />
                <Picker.Item label="15" value="15" />
                <Picker.Item label="16" value="16" />
                <Picker.Item label="17" value="17" />
                <Picker.Item label="18" value="18" />
                <Picker.Item label="19" value="19" />
                <Picker.Item label="20" value="20" />
                <Picker.Item label="21" value="21" />
                <Picker.Item label="22" value="22" />
                <Picker.Item label="23" value="23" />
                <Picker.Item label="24" value="24" />
                <Picker.Item label="25" value="25" />
                <Picker.Item label="26" value="26" />
                <Picker.Item label="27" value="27" />
                <Picker.Item label="28" value="28" />
                <Picker.Item label="29" value="29" />
                <Picker.Item label="30" value="30" />
                <Picker.Item label="31" value="31" />
              </Picker>
              <Picker
                style={[
                  styles.textInputBox,
                  {
                    color: Colors[colorScheme].textLessEmphasised,
                    backgroundColor: Colors[colorScheme].background,
                    borderColor: Colors[colorScheme].edging,
                  },
                ]}
                selectedValue={multipleEndDateMonth}
                onValueChange={(itemValue, itemIndex) =>
                  setMultipleEndDateMonth(itemValue)
                }
              >
                <Picker.Item label="Month" value="" />
                <Picker.Item label="Jan" value="01" />
                <Picker.Item label="Feb" value="02" />
                <Picker.Item label="Mar" value="03" />

                <Picker.Item label="Apr" value="04" />
                <Picker.Item label="May" value="05" />
                <Picker.Item label="Jun" value="06" />

                <Picker.Item label="Jul" value="07" />
                <Picker.Item label="Aug" value="08" />
                <Picker.Item label="Sep" value="09" />

                <Picker.Item label="Oct" value="10" />
                <Picker.Item label="Nov" value="11" />
                <Picker.Item label="Dec" value="12" />
              </Picker>
              <Picker
                style={[
                  styles.textInputBox,
                  {
                    color: Colors[colorScheme].textLessEmphasised,
                    backgroundColor: Colors[colorScheme].background,
                    borderColor: Colors[colorScheme].edging,
                  },
                ]}
                selectedValue={multipleEndDateYear}
                onValueChange={(itemValue, itemIndex) =>
                  setMultipleEndDateYear(itemValue)
                }
              >
                <Picker.Item label="Year" value="" />
                <Picker.Item label="2021" value="2021" />
                <Picker.Item label="2022" value="2022" />
                <Picker.Item label="2023" value="2023" />
                <Picker.Item label="2024" value="2024" />
              </Picker>
            </View>
          </View>
        </>
      ) : null}

      {/* REGULAR DATES */}
      {dateType === "REGULAR" ? (
        <>
          <View style={styles.textInputContainer}>
            <View style={styles.textInputLabelAndIconContainer}>
              <Feather
                name="thumbs-up"
                size={18}
                color={
                  regularStartDate.length < 10
                    ? "transparent"
                    : Colors.palette.logoGreen
                }
              />
              <Text
                style={[
                  { color: Colors[colorScheme].textLessEmphasised },
                  styles.textInputLabel,
                ]}
              >
                Start date:
              </Text>
            </View>
            <View
              style={{
                width: 315,
                flexDirection: "row",
                backgroundColor: "transparent",
              }}
            >
              <Picker
                style={[
                  styles.textInputBox,
                  {
                    color: Colors[colorScheme].textLessEmphasised,
                    backgroundColor: Colors[colorScheme].background,
                    borderColor: Colors[colorScheme].edging,
                  },
                ]}
                // itemStyle={{fontFamily: 'avenir-next', color: 'red'}}
                selectedValue={regularStartDateDay}
                onValueChange={(itemValue, itemIndex) =>
                  setRegularStartDateDay(itemValue)
                }
              >
                <Picker.Item label="Day" value="" />
                <Picker.Item label="1" value="01" />
                <Picker.Item label="2" value="02" />
                <Picker.Item label="3" value="03" />
                <Picker.Item label="4" value="04" />
                <Picker.Item label="5" value="05" />
                <Picker.Item label="6" value="06" />
                <Picker.Item label="7" value="07" />
                <Picker.Item label="8" value="08" />
                <Picker.Item label="9" value="09" />
                <Picker.Item label="10" value="10" />
                <Picker.Item label="11" value="11" />
                <Picker.Item label="12" value="12" />
                <Picker.Item label="13" value="13" />
                <Picker.Item label="14" value="14" />
                <Picker.Item label="15" value="15" />
                <Picker.Item label="16" value="16" />
                <Picker.Item label="17" value="17" />
                <Picker.Item label="18" value="18" />
                <Picker.Item label="19" value="19" />
                <Picker.Item label="20" value="20" />
                <Picker.Item label="21" value="21" />
                <Picker.Item label="22" value="22" />
                <Picker.Item label="23" value="23" />
                <Picker.Item label="24" value="24" />
                <Picker.Item label="25" value="25" />
                <Picker.Item label="26" value="26" />
                <Picker.Item label="27" value="27" />
                <Picker.Item label="28" value="28" />
                <Picker.Item label="29" value="29" />
                <Picker.Item label="30" value="30" />
                <Picker.Item label="31" value="31" />
              </Picker>
              <Picker
                style={[
                  styles.textInputBox,
                  {
                    color: Colors[colorScheme].textLessEmphasised,
                    backgroundColor: Colors[colorScheme].background,
                    borderColor: Colors[colorScheme].edging,
                  },
                ]}
                selectedValue={regularStartDateMonth}
                onValueChange={(itemValue, itemIndex) =>
                  setRegularStartDateMonth(itemValue)
                }
              >
                <Picker.Item label="Month" value="" />
                <Picker.Item label="Jan" value="01" />
                <Picker.Item label="Feb" value="02" />
                <Picker.Item label="Mar" value="03" />

                <Picker.Item label="Apr" value="04" />
                <Picker.Item label="May" value="05" />
                <Picker.Item label="Jun" value="06" />

                <Picker.Item label="Jul" value="07" />
                <Picker.Item label="Aug" value="08" />
                <Picker.Item label="Sep" value="09" />

                <Picker.Item label="Oct" value="10" />
                <Picker.Item label="Nov" value="11" />
                <Picker.Item label="Dec" value="12" />
              </Picker>
              <Picker
                style={[
                  styles.textInputBox,
                  {
                    color: Colors[colorScheme].textLessEmphasised,
                    backgroundColor: Colors[colorScheme].background,
                    borderColor: Colors[colorScheme].edging,
                  },
                ]}
                selectedValue={regularStartDateYear}
                onValueChange={(itemValue, itemIndex) =>
                  setRegularStartDateYear(itemValue)
                }
              >
                <Picker.Item label="Year" value="" />
                <Picker.Item label="2021" value="2021" />
                <Picker.Item label="2022" value="2022" />
                <Picker.Item label="2023" value="2023" />
                <Picker.Item label="2024" value="2024" />
              </Picker>
            </View>
          </View>

          <View style={styles.textInputContainer}>
            <View style={styles.textInputLabelAndIconContainer}>
              <Feather
                name="thumbs-up"
                size={18}
                color={
                  regularEndDate.length < 10
                    ? "transparent"
                    : Colors.palette.logoGreen
                }
              />
              <Text
                style={[
                  { color: Colors[colorScheme].textLessEmphasised },
                  styles.textInputLabel,
                ]}
              >
                End date:
              </Text>
            </View>
            <View
              style={{
                width: 315,
                flexDirection: "row",
                backgroundColor: "transparent",
              }}
            >
              <Picker
                style={[
                  styles.textInputBox,
                  {
                    color: Colors[colorScheme].textLessEmphasised,
                    backgroundColor: Colors[colorScheme].background,
                    borderColor: Colors[colorScheme].edging,
                  },
                ]}
                // itemStyle={{fontFamily: 'avenir-next', color: 'red'}}
                selectedValue={regularEndDateDay}
                onValueChange={(itemValue, itemIndex) =>
                  setRegularEndDateDay(itemValue)
                }
              >
                <Picker.Item label="Day" value="" />
                <Picker.Item label="1" value="01" />
                <Picker.Item label="2" value="02" />
                <Picker.Item label="3" value="03" />
                <Picker.Item label="4" value="04" />
                <Picker.Item label="5" value="05" />
                <Picker.Item label="6" value="06" />
                <Picker.Item label="7" value="07" />
                <Picker.Item label="8" value="08" />
                <Picker.Item label="9" value="09" />
                <Picker.Item label="10" value="10" />
                <Picker.Item label="11" value="11" />
                <Picker.Item label="12" value="12" />
                <Picker.Item label="13" value="13" />
                <Picker.Item label="14" value="14" />
                <Picker.Item label="15" value="15" />
                <Picker.Item label="16" value="16" />
                <Picker.Item label="17" value="17" />
                <Picker.Item label="18" value="18" />
                <Picker.Item label="19" value="19" />
                <Picker.Item label="20" value="20" />
                <Picker.Item label="21" value="21" />
                <Picker.Item label="22" value="22" />
                <Picker.Item label="23" value="23" />
                <Picker.Item label="24" value="24" />
                <Picker.Item label="25" value="25" />
                <Picker.Item label="26" value="26" />
                <Picker.Item label="27" value="27" />
                <Picker.Item label="28" value="28" />
                <Picker.Item label="29" value="29" />
                <Picker.Item label="30" value="30" />
                <Picker.Item label="31" value="31" />
              </Picker>
              <Picker
                style={[
                  styles.textInputBox,
                  {
                    color: Colors[colorScheme].textLessEmphasised,
                    backgroundColor: Colors[colorScheme].background,
                    borderColor: Colors[colorScheme].edging,
                  },
                ]}
                selectedValue={regularEndDateMonth}
                onValueChange={(itemValue, itemIndex) =>
                  setRegularEndDateMonth(itemValue)
                }
              >
                <Picker.Item label="Month" value="" />
                <Picker.Item label="Jan" value="01" />
                <Picker.Item label="Feb" value="02" />
                <Picker.Item label="Mar" value="03" />

                <Picker.Item label="Apr" value="04" />
                <Picker.Item label="May" value="05" />
                <Picker.Item label="Jun" value="06" />

                <Picker.Item label="Jul" value="07" />
                <Picker.Item label="Aug" value="08" />
                <Picker.Item label="Sep" value="09" />

                <Picker.Item label="Oct" value="10" />
                <Picker.Item label="Nov" value="11" />
                <Picker.Item label="Dec" value="12" />
              </Picker>
              <Picker
                style={[
                  styles.textInputBox,
                  {
                    color: Colors[colorScheme].textLessEmphasised,
                    backgroundColor: Colors[colorScheme].background,
                    borderColor: Colors[colorScheme].edging,
                  },
                ]}
                selectedValue={regularEndDateYear}
                onValueChange={(itemValue, itemIndex) =>
                  setRegularEndDateYear(itemValue)
                }
              >
                <Picker.Item label="Year" value="" />
                <Picker.Item label="2021" value="2021" />
                <Picker.Item label="2022" value="2022" />
                <Picker.Item label="2023" value="2023" />
                <Picker.Item label="2024" value="2024" />
              </Picker>
            </View>
          </View>

          <View style={styles.textInputContainer}>
            <View style={styles.textInputLabelAndIconContainer}>
              <Feather
                name="thumbs-up"
                size={18}
                color={
                  isMondaySwitchEnabled ||
                  isTuesdaySwitchEnabled ||
                  isWednesdaySwitchEnabled ||
                  isThursdaySwitchEnabled ||
                  isFridaySwitchEnabled ||
                  isSaturdaySwitchEnabled ||
                  isSundaySwitchEnabled
                    ? Colors.palette.logoGreen
                    : "transparent"
                }
              />
              <Text
                style={[
                  { color: Colors[colorScheme].textLessEmphasised },
                  styles.textInputLabel,
                ]}
              >
                Day(s):
              </Text>
            </View>
            <View
              style={[
                styles.switchGroupContainer,
                {
                  backgroundColor: Colors[colorScheme].background,
                  borderColor: Colors[colorScheme].edging,
                },
              ]}
            >
              <View style={styles.switchRowContainer}>
                <Switch
                  onValueChange={toggleMondaySwitch}
                  value={isMondaySwitchEnabled}
                  trackColor={{
                    false: Colors[colorScheme].edging,
                    true: Colors.palette.menuInactive,
                  }}
                  thumbColor={
                    isMondaySwitchEnabled
                      ? Colors.palette.logoGreen
                      : Colors.palette.grey
                  }
                />
                <Text
                  style={[
                    styles.switchLabel,
                    { color: Colors[colorScheme].text },
                  ]}
                >
                  Monday
                </Text>
              </View>
              <View style={styles.switchRowContainer}>
                <Switch
                  onValueChange={toggleTuesdaySwitch}
                  value={isTuesdaySwitchEnabled}
                  trackColor={{
                    false: Colors[colorScheme].edging,
                    true: Colors.palette.menuInactive,
                  }}
                  thumbColor={
                    isTuesdaySwitchEnabled
                      ? Colors.palette.logoGreen
                      : Colors.palette.grey
                  }
                />
                <Text
                  style={[
                    styles.switchLabel,
                    { color: Colors[colorScheme].text },
                  ]}
                >
                  Tuesday
                </Text>
              </View>

              <View style={styles.switchRowContainer}>
                <Switch
                  onValueChange={toggleWednesdaySwitch}
                  value={isWednesdaySwitchEnabled}
                  trackColor={{
                    false: Colors[colorScheme].edging,
                    true: Colors.palette.menuInactive,
                  }}
                  thumbColor={
                    isWednesdaySwitchEnabled
                      ? Colors.palette.logoGreen
                      : Colors.palette.grey
                  }
                />
                <Text
                  style={[
                    styles.switchLabel,
                    { color: Colors[colorScheme].text },
                  ]}
                >
                  Wednesday
                </Text>
              </View>

              <View style={styles.switchRowContainer}>
                <Switch
                  onValueChange={toggleThursdaySwitch}
                  value={isThursdaySwitchEnabled}
                  trackColor={{
                    false: Colors[colorScheme].edging,
                    true: Colors.palette.menuInactive,
                  }}
                  thumbColor={
                    isThursdaySwitchEnabled
                      ? Colors.palette.logoGreen
                      : Colors.palette.grey
                  }
                />
                <Text
                  style={[
                    styles.switchLabel,
                    { color: Colors[colorScheme].text },
                  ]}
                >
                  Thursday
                </Text>
              </View>

              <View style={styles.switchRowContainer}>
                <Switch
                  onValueChange={toggleFridaySwitch}
                  value={isFridaySwitchEnabled}
                  trackColor={{
                    false: Colors[colorScheme].edging,
                    true: Colors.palette.menuInactive,
                  }}
                  thumbColor={
                    isFridaySwitchEnabled
                      ? Colors.palette.logoGreen
                      : Colors.palette.grey
                  }
                />
                <Text
                  style={[
                    styles.switchLabel,
                    { color: Colors[colorScheme].text },
                  ]}
                >
                  Friday
                </Text>
              </View>

              {/* <View style={styles.switchRowContainer}>
                <Switch
                  onValueChange={toggleSaturdaySwitch}
                  value={isSaturdaySwitchEnabled}
                  trackColor={{
                    false: Colors[colorScheme].edging,
                    true: Colors.palette.menuInactive,
                  }}
                  thumbColor={
                    isSaturdaySwitchEnabled
                      ? Colors.palette.logoGreen
                      : Colors.palette.grey
                  }
                />
                <Text
                  style={[
                    styles.switchLabel,
                    { color: Colors[colorScheme].text },
                  ]}
                >
                  Saturday
                </Text>
              </View> */}

              {/* <View style={styles.switchRowContainer}>
                <Switch
                  onValueChange={toggleSundaySwitch}
                  value={isSundaySwitchEnabled}
                  trackColor={{
                    false: Colors[colorScheme].edging,
                    true: Colors.palette.menuInactive,
                  }}
                  thumbColor={
                    isSundaySwitchEnabled
                      ? Colors.palette.logoGreen
                      : Colors.palette.grey
                  }
                />
                <Text
                  style={[
                    styles.switchLabel,
                    { color: Colors[colorScheme].text },
                  ]}
                >
                  Sunday
                </Text>
              </View> */}
            </View>
          </View>
        </>
      ) : null}

      <View style={styles.textInputContainer}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={
              isAmbitionSwitchEnabled ||
              isCollaborationSwitchEnabled ||
              isCommunicationSwitchEnabled ||
              isCommitmentSwitchEnabled ||
              isCourageSwitchEnabled ||
              isCompassionSwitchEnabled ||
              isCriticalThinkingSwitchEnabled ||
              isInfluenceSwitchEnabled ||
              isInitiativeSwitchEnabled ||
              isSelfAwarenessSwitchEnabled ||
              isIntegritySwitchEnabled ||
              isInclusivenessSwitchEnabled
                ? Colors.palette.logoGreen
                : "transparent"
            }
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Character development:
          </Text>
        </View>
        <View
          style={[
            styles.switchGroupContainer,
            {
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
        >
          <View style={styles.switchRowContainer}>
            <Switch
              onValueChange={toggleAmbitionSwitch}
              value={isAmbitionSwitchEnabled}
              trackColor={{
                false: Colors[colorScheme].edging,
                true: Colors.palette.menuInactive,
              }}
              thumbColor={
                isAmbitionSwitchEnabled
                  ? Colors.palette.logoGreen
                  : Colors.palette.grey
              }
            />
            <Text
              style={[styles.switchLabel, { color: Colors[colorScheme].text }]}
            >
              Ambition
            </Text>
          </View>
          <View style={styles.switchRowContainer}>
            <Switch
              onValueChange={toggleCollaborationSwitch}
              value={isCollaborationSwitchEnabled}
              trackColor={{
                false: Colors[colorScheme].edging,
                true: Colors.palette.menuInactive,
              }}
              thumbColor={
                isCollaborationSwitchEnabled
                  ? Colors.palette.logoGreen
                  : Colors.palette.grey
              }
            />
            <Text
              style={[styles.switchLabel, { color: Colors[colorScheme].text }]}
            >
              Collaboration
            </Text>
          </View>

          <View style={styles.switchRowContainer}>
            <Switch
              onValueChange={toggleCommunicationSwitch}
              value={isCommunicationSwitchEnabled}
              trackColor={{
                false: Colors[colorScheme].edging,
                true: Colors.palette.menuInactive,
              }}
              thumbColor={
                isCommunicationSwitchEnabled
                  ? Colors.palette.logoGreen
                  : Colors.palette.grey
              }
            />
            <Text
              style={[styles.switchLabel, { color: Colors[colorScheme].text }]}
            >
              Communication
            </Text>
          </View>

          <View style={styles.switchRowContainer}>
            <Switch
              onValueChange={toggleCommitmentSwitch}
              value={isCommitmentSwitchEnabled}
              trackColor={{
                false: Colors[colorScheme].edging,
                true: Colors.palette.menuInactive,
              }}
              thumbColor={
                isCommitmentSwitchEnabled
                  ? Colors.palette.logoGreen
                  : Colors.palette.grey
              }
            />
            <Text
              style={[styles.switchLabel, { color: Colors[colorScheme].text }]}
            >
              Commitment
            </Text>
          </View>

          <View style={styles.switchRowContainer}>
            <Switch
              onValueChange={toggleCourageSwitch}
              value={isCourageSwitchEnabled}
              trackColor={{
                false: Colors[colorScheme].edging,
                true: Colors.palette.menuInactive,
              }}
              thumbColor={
                isCourageSwitchEnabled
                  ? Colors.palette.logoGreen
                  : Colors.palette.grey
              }
            />
            <Text
              style={[styles.switchLabel, { color: Colors[colorScheme].text }]}
            >
              Courage
            </Text>
          </View>

          <View style={styles.switchRowContainer}>
            <Switch
              onValueChange={toggleCompassionSwitch}
              value={isCompassionSwitchEnabled}
              trackColor={{
                false: Colors[colorScheme].edging,
                true: Colors.palette.menuInactive,
              }}
              thumbColor={
                isCompassionSwitchEnabled
                  ? Colors.palette.logoGreen
                  : Colors.palette.grey
              }
            />
            <Text
              style={[styles.switchLabel, { color: Colors[colorScheme].text }]}
            >
              Compassion
            </Text>
          </View>

          <View style={styles.switchRowContainer}>
            <Switch
              onValueChange={toggleCriticalThinkingSwitch}
              value={isCriticalThinkingSwitchEnabled}
              trackColor={{
                false: Colors[colorScheme].edging,
                true: Colors.palette.menuInactive,
              }}
              thumbColor={
                isCriticalThinkingSwitchEnabled
                  ? Colors.palette.logoGreen
                  : Colors.palette.grey
              }
            />
            <Text
              style={[styles.switchLabel, { color: Colors[colorScheme].text }]}
            >
              Critical Thinking
            </Text>
          </View>

          <View style={styles.switchRowContainer}>
            <Switch
              onValueChange={toggleInfluenceSwitch}
              value={isInfluenceSwitchEnabled}
              trackColor={{
                false: Colors[colorScheme].edging,
                true: Colors.palette.menuInactive,
              }}
              thumbColor={
                isInfluenceSwitchEnabled
                  ? Colors.palette.logoGreen
                  : Colors.palette.grey
              }
            />
            <Text
              style={[styles.switchLabel, { color: Colors[colorScheme].text }]}
            >
              Influence
            </Text>
          </View>

          <View style={styles.switchRowContainer}>
            <Switch
              onValueChange={toggleInitiativeSwitch}
              value={isInitiativeSwitchEnabled}
              trackColor={{
                false: Colors[colorScheme].edging,
                true: Colors.palette.menuInactive,
              }}
              thumbColor={
                isInitiativeSwitchEnabled
                  ? Colors.palette.logoGreen
                  : Colors.palette.grey
              }
            />
            <Text
              style={[styles.switchLabel, { color: Colors[colorScheme].text }]}
            >
              Initiative
            </Text>
          </View>

          <View style={styles.switchRowContainer}>
            <Switch
              onValueChange={toggleInclusivenessSwitch}
              value={isInclusivenessSwitchEnabled}
              trackColor={{
                false: Colors[colorScheme].edging,
                true: Colors.palette.menuInactive,
              }}
              thumbColor={
                isInclusivenessSwitchEnabled
                  ? Colors.palette.logoGreen
                  : Colors.palette.grey
              }
            />
            <Text
              style={[styles.switchLabel, { color: Colors[colorScheme].text }]}
            >
              Inclusiveness
            </Text>
          </View>

          <View style={styles.switchRowContainer}>
            <Switch
              onValueChange={toggleIntegritySwitch}
              value={isIntegritySwitchEnabled}
              trackColor={{
                false: Colors[colorScheme].edging,
                true: Colors.palette.menuInactive,
              }}
              thumbColor={
                isIntegritySwitchEnabled
                  ? Colors.palette.logoGreen
                  : Colors.palette.grey
              }
            />
            <Text
              style={[styles.switchLabel, { color: Colors[colorScheme].text }]}
            >
              Integrity
            </Text>
          </View>

          <View style={styles.switchRowContainer}>
            <Switch
              onValueChange={toggleSelfAwarenessSwitch}
              value={isSelfAwarenessSwitchEnabled}
              trackColor={{
                false: Colors[colorScheme].edging,
                true: Colors.palette.menuInactive,
              }}
              thumbColor={
                isSelfAwarenessSwitchEnabled
                  ? Colors.palette.logoGreen
                  : Colors.palette.grey
              }
            />
            <Text
              style={[styles.switchLabel, { color: Colors[colorScheme].text }]}
            >
              Self Awareness
            </Text>
          </View>
        </View>
      </View>

      {/* STAFF  */}
      <View style={[styles.textInputContainer, { alignItems: "baseline" }]}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={
              staffLeader.length < 1 ? "transparent" : Colors.palette.logoGreen
            }
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Staff name:
          </Text>
        </View>

        <View
          style={[
            styles.textInputBox,
            {
              outlineStyle: "none",
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
        >
          {staffLeader.map((user) => {
            //Item in the list of users to add the badge to
            return (
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: Colors[colorScheme].background,
                }}
                key={user.id}
              >
                <Text
                  style={{ color: Colors[colorScheme].text, fontSize: 16 }}
                  numberOfLines={1}
                >
                  {user.preferredFirstName} {user.surname}
                </Text>
                <Pressable onPress={() => removeFromArray(user.id)}>
                  <Feather
                    name="trash-2"
                    size={20}
                    color={Colors.palette.softRed}
                  />
                </Pressable>
              </View>
            );
          })}

          {staffLeader.length < 1 ? (
            <>
              <SearchBarMobile
                searchPhrase={search}
                setSearchPhrase={(text) => searchFilter(text)}
                clicked={clicked}
                setClicked={setClicked}
              />

              {search ? (
                <FlatList
                  data={filteredData}
                  numColumns={1}
                  renderItem={({ item }) => (
                    // <ThumbnailTimeline id={item.id}/>
                    <Pressable
                      onPress={
                        () =>
                          addToArray({
                            id: item.id,
                            surname: item.surname,
                            preferredFirstName: item.preferredFirstName,
                          })
                        // {highlightSelection(item.id)}}
                      }
                    >
                      <View style={{ backgroundColor: "transparent" }}>
                        <Text
                          style={{
                            color: Colors[colorScheme].text,
                            fontSize: 16,
                            paddingVertical: 5,
                            marginLeft: 10,
                          }}
                        >
                          {item.preferredFirstName} {item.surname}
                        </Text>
                      </View>
                    </Pressable>
                  )}
                  keyExtractor={(item) => item.id}
                  key={4}
                />
              ) : null}
            </>
          ) : null}
        </View>
      </View>

      {/* USERS TO BADGE  */}
      <View style={[styles.textInputContainer, { alignItems: "baseline" }]}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={
              usersToBadge.length < 1 ? "transparent" : Colors.palette.logoGreen
            }
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Awarded to:
          </Text>
        </View>

        <View
          style={[
            styles.textInputBox,
            {
              outlineStyle: "none",
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
        >
          {usersToBadge.map((user) => {
            //Item in the list of users to add the badge to
            return (
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: Colors[colorScheme].background,
                }}
                key={user.id}
              >
                <Text
                  style={{ color: Colors[colorScheme].text, fontSize: 16 }}
                  numberOfLines={1}
                >
                  {user.preferredFirstName} {user.surname}
                </Text>
                <Pressable onPress={() => removeFromUsersArray(user.id)}>
                  <Feather
                    name="trash-2"
                    size={20}
                    color={Colors.palette.softRed}
                  />
                </Pressable>
              </View>
            );
          })}

          {/* {usersToBadge.length < 1 ? ( */}
          <>
            <SearchBarMobile
              searchPhrase={searchUsers}
              setSearchPhrase={(text) => searchFilterUsers(text)}
              clicked={clickedUsers}
              setClicked={setClickedUsers}
            />

            {searchUsers ? (
              <FlatList
                data={filteredDataUsers}
                numColumns={1}
                renderItem={({ item }) => (
                  // <ThumbnailTimeline id={item.id}/>
                  <Pressable
                    onPress={() =>
                      addToUsersArray({
                        id: item.id,
                        surname: item.surname,
                        preferredFirstName: item.preferredFirstName,
                      })
                    }
                  >
                    <View style={{ backgroundColor: "transparent" }}>
                      <Text
                        style={{
                          color: Colors[colorScheme].text,
                          fontSize: 16,
                          paddingVertical: 5,
                          marginLeft: 10,
                        }}
                      >
                        {item.preferredFirstName} {item.surname}
                      </Text>
                    </View>
                  </Pressable>
                )}
                keyExtractor={(item) => item.id}
                key={4}
              />
            ) : null}
          </>
          {/* ) : null} */}
        </View>
      </View>

      <View
        style={{ backgroundColor: "transparent", alignItems: "center" }}
      >{triggerWarning? <View style={{backgroundColor:Colors[colorScheme].almostBackground, padding:10, borderRadius:10, borderColor:Colors.palette.softRed, borderWidth:1, margin:10}}><Text style={{color:Colors.palette.softRed}}>There are some issues with the form. Please fix these before trying again.</Text></View>:null}
      <Pressable
        onPress={submitRecords}
        style={{
          alignItems: "center",
          width: 120,
          backgroundColor: Colors.palette.logoGreen,
          padding: 10,
          margin: 20,
          borderRadius: 10,
        }}
      >
        <Feather name="upload" size={48} color={Colors[colorScheme].background} />
        <BoldText
          style={{ textAlign: "center", color: Colors[colorScheme].background }}
        >
          Submit details
        </BoldText>
        
      </Pressable>
      
      </View>
    </ScrollView>
  );
};
export default NewOpportunityForm;

const styles = StyleSheet.create({
  textInputBox: {
    borderWidth: 1,
    margin: 10,
    padding: 10,
    borderRadius: 8,
    fontSize: 16,
    fontFamily: "avenir-next",
    width: 300,
  },
  textInputLabel: { fontSize: 16, marginHorizontal: 10, fontWeight: "800" },
  textInputContainer: {
    flexDirection: "row",
    padding: 5,
    width: 600,
    backgroundColor: "transparent",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textInputLabelAndIconContainer: {
    backgroundColor: "tranparent",
    flexDirection: "row",
    alignItems: "center",
  },
  switchRowContainer: {
    backgroundColor: "transparent",
    flexDirection: "row",
    flex: 1,
    padding: 5,
    // alignItems: "s",
    justifyContent: "flex-start",
    alignSelf: "stretch",
    marginLeft: 20,
  },
  switchGroupContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    margin: 10,
    padding: 10,
    borderRadius: 8,
    fontSize: 16,
    width: 300,
  },
  switchLabel: { fontSize: 16, marginHorizontal: 40 },
});
