import { StyleSheet, Image, FlatList, Pressable, ActivityIndicator} from "react-native";
import { View, Text } from "../../constants/Themed";
import { BoldText } from "../../constants/StyledText";
import { AdminStackParamList, DrawerRootTabParamList, RootTabScreenProps } from "../../navigation/types";
import Colors from '../../constants/Colors'
import * as WebBrowser from "expo-web-browser";
import DynamicBadge from "../../components/DynamicBadge";
import {Auth} from 'aws-amplify';
import { Storage } from "aws-amplify";
import Constants from 'expo-constants';
import { useEffect, useState } from "react";
import { Feather } from '@expo/vector-icons';
import useColorScheme from "../../hooks/useColorScheme";
import SignedURLImage from "../../components/SignedURLImage";
// import Animated, {FadeIn, SlideInLeft, SlideInUp} from 'react-native-reanimated';
import CardOpportunity from '../../components/desktop/CardOpportunity';
// import Amplify from 'aws-amplify-react-native';
// import { S3 } from 'aws-amplify-react-native';
import Amplify from 'aws-amplify';
// import { Storage } from 'aws-amplify';

import { AwardedBadge } from './../../models';

import { API, graphqlOperation } from "aws-amplify";
import HousePointsWeekly from "../../components/HousePointsWeekly";
import NewOpportunityForm from "../../components/NewOpportunityForm";
import SignedURLImage2 from "../../components/SignedURLImage2";
import ShoutOutIcon from "../../../assets/icons/ShoutOut";
// import * as mutations from './../../graphql/mutations';


// // Set the Secrets Manager secret and key
// Amplify.configure({
//   Storage: {
//     region: 'eu-west-2',
//     credentials: {
//       secretAccessKey: 'recognition/staging/serverlessimagehandler',
//       accessKeyId: 'thisIsTheKey'
//     }
//   }
// });




export const createAwardedBadge = /* GraphQL */ `
  mutation CreateAwardedBadge(
    $input: CreateAwardedBadgeInput!
    $condition: ModelAwardedBadgeConditionInput
  ) {
    createAwardedBadge(input: $input, condition: $condition) {
      id
      dateAwarded
      scoringData1Value
      scoringData2Value
      scoringData3Value
      groupingData1Value
      groupingData2Value
      groupingData3Value
      opportunityID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

//note, you have removed the line about limit from the top of this query (where it gets defined as an int) and added a limit of 2000. If you don't do this, you don't get all of the results!
export const listOpportunities = /* GraphQL */ `
  query ListOpportunities(
    $filter: ModelOpportunityFilterInput
   
    $nextToken: String
  ) {
    listOpportunities(filter: $filter, limit: 2000, nextToken: $nextToken) {
      items {
        id
        isActive
        name
        description
        logoURL
        location
        colour
        engagementLevel
        availableTo
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        User {
          id
          title
          surname
        }
        opportunitycategoryID
        OpportunityCategory {
          id
          name
          logoWhiteURI
          logoColourURI
          logoGreyURI
          colour
        }
        Mappings {
          items {
            level
            Criteria {
              name
              logoColourURI
            }
          }
        }
        OpportunityAvailabilities {
          items {
            groupingData3
          }
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;


export default function AdminRoot({
  navigation,
}: RootTabScreenProps<"Admin">) {



  const [isLoading, setIsLoading] = useState(true);
  const [masterData, setMasterData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showForm, setShowForm] = useState(false)
  //the cloud query for a list (that has a filter applied here)
  const fetchOpportunitiesList = async () => {
    setBadgesRequested(!badgesRequested)
    const response = await API.graphql(
      graphqlOperation(listOpportunities)
    );
    setMasterData(response.data.listOpportunities.items);


    setFilteredData(response.data.listOpportunities.items
      .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) //sort alphateically


      );
    setIsDone(true)
  };

  



  

  

  function printPage() {
    window.print();
  }



  const sendNotification = async ()=> {

  const post = await fetch("https://exp.host/--/api/v2/push/send", {
    mode: 'no-cors',
    method: 'POST',
    headers: {
    
      'host':'exp.host',
      'accept': 'application/json',
      'accept-encoding':'gzip, deflate',
      'content-type':'application/json',
    },
    body: `{
       “To”:’ExponentPushToken[bzCGMvG40lKbY8_8KZYLGh]’,
       “Title”: “Nice title!”,
       “body”: “this is the main bit of the message”
      }`,
    });
    
  }



  async function sendPushNotification() {
    const message = {
      to: 'ExponentPushToken[bzCGMvG40lKbY8_8KZYLGh]',
      sound: 'default',
      title: 'Original Title',
      body: 'And here is the body!',
      data: { someData: 'goes here' },
    };
  
    await fetch('https://exp.host/--/api/v2/push/send', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-encoding': 'gzip, deflate',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    });
  }

  const [currentLoggedInUser, setCurrentLoggedInUser] = useState();
  const [isDone, setIsDone] = useState(false)

  const [badgesRequested, setBadgesRequested] = useState(false)
  const colorScheme = useColorScheme();
  const getUserDetails = async () => {
    const { attributes } = await Auth.currentAuthenticatedUser();
    setCurrentLoggedInUser(attributes)
    setIsDone(true)
  }
  
  useEffect(() => {
   getUserDetails();

  }, []);


 




  return (
    
      <View style={[styles.root, {backgroundColor: Colors[colorScheme].almostBackground}]}>
  
        
  
        <View style={{flexDirection:'row', backgroundColor:'transparent'}}>
  

  <Pressable onPress={() =>
      navigation.navigate('BadgesList',
          )
    }style={{alignItems:'center',width:120,backgroundColor: Colors.palette.logoGreen, padding:10, margin: 20, borderRadius:10}}>
  <Feather name="list" size={48} color={Colors[colorScheme].background} />
          <BoldText style={{textAlign: 'center',color: Colors[colorScheme].background}}>Badges list</BoldText>
          <BoldText style={{textAlign: 'center',color: Colors[colorScheme].background}}>(A-Z)</BoldText>

  </Pressable>


  <Pressable onPress={() =>
      navigation.navigate('AddNewBadge',
          )
    }style={{alignItems:'center',width:120,backgroundColor: Colors.palette.logoGreen, padding:10, margin: 20, borderRadius:10}}>
  <Feather name="plus-circle" size={48} color={Colors[colorScheme].background} />
          <BoldText style={{textAlign: 'center', color: Colors[colorScheme].background}}>Award new badge</BoldText>

  </Pressable>

  <Pressable onPress={() =>
      navigation.navigate('AdminHouseCompetitions',
          )
    }style={{alignItems:'center',width:120,backgroundColor: Colors.palette.logoGreen, padding:10, margin: 20, borderRadius:10}}>
  <Feather name="award" size={48} color={Colors[colorScheme].background} />
 
          <BoldText style={{textAlign: 'center', color: Colors[colorScheme].background}}>Award house competition</BoldText>

  </Pressable>

  <Pressable onPress={async () => {
          await sendPushNotification();
        }
    }style={{alignItems:'center',width:120,backgroundColor: Colors.palette.logoGreen, padding:10, margin: 20, borderRadius:10}}>
  <Feather name="smartphone" size={48} color={Colors[colorScheme].background} />
 
          <BoldText style={{textAlign: 'center', color: Colors[colorScheme].background}}>Send notification</BoldText>

  </Pressable>

  <Pressable onPress={() =>
      navigation.navigate('AdminAddShoutOut',
          )
    }style={{alignItems:'center',width:120,backgroundColor: Colors.palette.logoGreen, padding:10, margin: 20, borderRadius:10}}>
  {/* <Feather name="award" size={48} color={Colors[colorScheme].background} /> */}
 <View style={{backgroundColor:'transparent', width:65, height:65}}><ShoutOutIcon color={'white'} /></View>
          <BoldText style={{textAlign: 'center', color: Colors[colorScheme].background}}>Add ShoutOut</BoldText>

  </Pressable>
  


  {/* <SignedURLImage
  filter={'/filters:quality(100)/filters:grayscale()/fit-in/100x100'}
  fileKey={'/public/organisation-assets/carrmanor/staffPhotos/ALaycock.jpg'}
  imageWidth={100}
  imageHeight={100}
  /> */}





  </View>





  




    
      </View>
    );

  


     



}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: "center",
    // justifyContent: "center",
    backgroundColor: "transparent",
    width: '100%',
    alignSelf: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },

});
