import { StyleSheet, Image, Pressable } from "react-native";
import { Text, View } from "../constants/Themed";
import React, { useEffect, useRef, useState } from "react";
import {useNavigation} from '@react-navigation/native';
import {Storage } from "aws-amplify";
import Colors from '../constants/Colors';

const CardOpportunityCategory = (props) => {
  const { category } = props;


  

  const isCancelled = useRef(false);
  const [downloadedImageURI, setDownloadedImageURI] = useState();
  const getImage = async () => {
    const file = await Storage.get(category.logoWhiteURI, { level: "public" }); //get file using query data
    if (!isCancelled.current) {
    setDownloadedImageURI(file); //store imageURI in state variable to be used elsewhere on the page
    }
  };





  useEffect(() => {
    getImage();
    
    return () => {
      isCancelled.current = true;
    };

  }, []);


  const navigation = useNavigation();
  const NavigateToOpportunitiesList = () => {
    navigation.navigate('OpportunitiesList', { catID: category.id, catName: category.name, catDescription: category.description }) // TYPESCRIPT ERROR. Also, you shouldn't be sending the whole object through navigatin, just the ID of the shoutout then query the database on the detail screen
  }
  return (
    <Pressable onPress={NavigateToOpportunitiesList}>
      <View style={[styles.card, { backgroundColor: category.colour }]}>
        <View style={{width: 100, paddingRight:10, backgroundColor:'transparent', alignItems:'center'}}>
      <Image source={{ uri: downloadedImageURI }} style={styles.icon} />
      </View>
      <View style={{backgroundColor:'transparent', flex:1, marginTop:30, alignSelf:'flex-start'}}>
      <Text style={styles.title}>{category.name}</Text>
      <Text style={{color:Colors.palette.darkGrey, fontSize:12}}>{category.description}</Text>
      </View>
    </View></Pressable>
  );
};

export default CardOpportunityCategory;

const styles = StyleSheet.create({
  icon: {
    width: 80,
    height: 80,
  },
  card: {
    width: 350,
    height: 150,
    borderRadius: 10,
    alignItems: "center",
    // justifyContent: "center",
    margin: 10,
    flexDirection:'row',
    padding:10,
    // flex:1
  },
  title: {
    fontSize: 24,
    // alignSelf: 'center',
    fontWeight:'800',
    marginBottom:5,
  },
});
