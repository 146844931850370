import {
  StyleSheet,
  Image,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { View, Text } from "../../constants/Themed";
import ShoutOutIcon from "../../../assets/icons/ShoutOut";
import Colors from "../../constants/Colors";
import { BoldText } from "../../constants/StyledText";
import useColorScheme from "../../hooks/useColorScheme";
import { RouteProp, useRoute } from "@react-navigation/native";
import TaggedTimeline from '../../components/mobile/TaggedTimeline';
import { RootStackParamList } from "../../navigation/types";

export default function ShoutOutsDetail() {
  const { height } = useWindowDimensions();
  const colorScheme = useColorScheme();
  const route = useRoute<RouteProp<RootStackParamList, 'ShoutOutsDetail'>>();

  const { shoutOutID } = route.params; //this isn't type checked and is bad practice. According to the Istagram course by Vadim (video 4.3) we should only be sending the shoutout ID and then querying the database on the detail screen



  // RUNNING A QUERY (...KIND OF!) ON A LOCAL JSON
  const shoutOutData = require('../../models/DummyQueryShoutOutsWithTags.json') //which JSON?
  const filteredData = shoutOutData.filter(i => i.id == shoutOutID) //where ID = ID from the route
  const arrayRemovedData = filteredData.shift(); //pull out the first item in the array (there is only because of the line above)
  const {image, date, name, tagged, badgesAwarded, housePoints, volunteeringHours} = arrayRemovedData //destructure the fields you want.



  return (
    <ScrollView style={styles.root}>
      <View style={[styles.contentContainer, { width: height - 64 }]}>
        {/* The Photo */}
        <View style={{ height: height - 64, width: height - 64 }}>
          {/* AJL: 64 in the line above is the height of the main site navigation. This means the photo will be a square taking up the remaining height of the scren */}
          <Image
            source={{
              uri: image
            }}
            style={styles.image}
          />

          <View style={styles.iconPosition}>
            <ShoutOutIcon color={Colors.palette.logoGreen} />
          </View>
        </View>

        {/* The meta data */}
        <View>
          {/* Who posted, what's the title and when? */}
          <View
            style={[
              styles.contributionContainer,
              { backgroundColor: Colors[colorScheme].almostBackground },
            ]}
          >
            <Image
              source={{
                uri: "https://media-exp1.licdn.com/dms/image/C5603AQFg9TFs1Dz9QQ/profile-displayphoto-shrink_200_200/0/1517394698763?e=1652918400&v=beta&t=SKxrTG9zU9iKxQZpk2sG-LzlynVo_OB5YYEx4qaG2RQ",
              }}
              style={styles.contributorProfileImage}
            />

            <View
              style={{ backgroundColor: "transparent", marginHorizontal: 10 }}
            >
              <BoldText
                style={{ color: Colors.palette.secondaryBlue, fontSize: 18 }}
              >
                {name}
              </BoldText>
              <Text>Mr Laycock</Text>
              <Text
                style={{
                  color: Colors[colorScheme].textLessEmphasised,
                  fontSize: 10,
                }}
              >
                {date}
              </Text>
            </View>
          </View>



{/* USERS THAT ARE TAGGED IN THE SHOUTOUT */}

<View
          style={[
            styles.taggedRowContainer,
            { backgroundColor: Colors[colorScheme].almostBackground },
          ]}
        >
          {tagged?.map((tagged) => (
            <TaggedTimeline key={tagged.id} tagged={tagged} />
          ))}
          {/* The above uses mapping rather than a flatlist to render the tagged users.   */}
        </View>



        
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: "transparent",
  },
  contentContainer: {
    // width: 600,
    alignSelf: "center",
  },
  iconPosition: {
    width: 70,
    height: 70,
    position: "absolute",
    alignSelf: "flex-end",
    backgroundColor: "transparent",
  },
  image: {
    aspectRatio: 1,
    height: "100%",
  },
  contributorProfileImage: {
    width: 60,
    height: 60,
    borderRadius: 35,
    marginHorizontal: 10,
  },
  contributionContainer: {
    flexDirection: "row",
    flex: 1,
    paddingVertical: 10,
    alignItems: "center",
  },
  taggedRowContainer: {
    // paddingHorizontal: 10,
    alignItems: "flex-end",
    flexDirection: "row-reverse",
    
  },
  taggedProfileImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginBottom: 5,
  },
  taggedItemContainer: {
    alignItems: "center",
    margin: 5,
    width: 90,
    backgroundColor: "transparent",
  },
  taggedName:{
    fontSize: 10,
    flexWrap: 'wrap',
    textAlign: 'center',
  }
});
