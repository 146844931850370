import React from "react";
import { StyleSheet, TextInput, Keyboard, Pressable, Platform } from "react-native";
import { Feather } from "@expo/vector-icons";
import { Text, View } from "../../constants/Themed";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";

const SearchBarMobile = (props: {
  clicked: any;
  searchPhrase: string | undefined;
  setSearchPhrase: ((text: string) => void) | undefined;
  setClicked: (arg0: boolean) => void;
}) => {
  const colorScheme = useColorScheme();

  return (
    <View style={styles.container}>
      <View
        style={
          !props.clicked
            ? [
                styles.searchBar__unclicked,
                {
                  backgroundColor: Colors[colorScheme].almostBackground,
                  borderColor: Colors[colorScheme].edging,
                },
              ]
            : [
                styles.searchBar__clicked,
                {
                  backgroundColor: Colors[colorScheme].almostBackground,
                  borderColor: Colors.palette.logoGreen,
                },
              ]
        }
      >
        {/* search Icon */}
        <Feather
          name="search"
          size={20}
          color={Colors[colorScheme].text}
          style={{ marginLeft: 1 }}
        />
        {/* Input field */}
        <TextInput
          style={[
            // Platform.OS === "web" ? {outlineWidth:0}: null,

            Platform.OS === "web" ? {outlineStyle:'none'}: null,
            styles.input,
            { color: Colors[colorScheme].text, }
          ]


           }
            
            
          //   Platform.select({
          //     web: {
          //       outlineColor: 'orange',
          //     },
          //   })
          // }
            
            
            // [,
               
          
          placeholder="Search"
          placeholderTextColor={Colors[colorScheme].textLessEmphasised}
          value={props.searchPhrase}
          onChangeText={props.setSearchPhrase}
          onFocus={() => {
            props.setClicked(true);
          }}
        />
        {/* cross Icon, depending on whether the search bar is clicked or not */}
        {/* {props.clicked && (
          <Entypo name="cross" size={20} color="black" style={{ padding: 1, }} onPress={() => {
              props.setSearchPhrase("")
          }}/>
        )} */}
      </View>
      {/* cancel button, depending on whether the search bar is clicked or not */}
      {props.clicked && (
        <View style={{ flex: 1, backgroundColor: 'transparent' }}>
          <Pressable
            onPress={() => {
              Keyboard.dismiss();
              props.setClicked(false);
              props.setSearchPhrase("");
            }}
          >
            <Text
              style={{
                fontFamily: "avenir-next",
                fontSize: 12,
                color: Colors[colorScheme].text,
                // marginLeft: 5,
                alignSelf:'center',
              }}
            >
              Cancel
            </Text>
          </Pressable>
        </View>
      )}
    </View>
  );
};
export default SearchBarMobile;

// styles
const styles = StyleSheet.create({
  container: {
    // backgroundColor: 'transparent',
    // marginTop: 50,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    // width: 500,
    width: "100%",
  },
  searchBar__unclicked: {
    padding: 10,
    margin: 10,
    flexDirection: "row",
    width: "95%",

    borderWidth: 1,
    borderRadius: 8,
    alignItems: "center",
    // justifyContent: "space-evenly",
    // justifyContent: 'space-between',
  },
  searchBar__clicked: {
    padding: 10,
    margin: 10,
    flexDirection: "row",
    flex: 4,
    // width: "80%",

    borderWidth: 1,
    borderRadius: 8,
    alignItems: "center",
    // justifyContent: 'space-between',
  },
  input: {
    fontSize: 16,
    marginLeft: 10,
    // outlineStyle: 'none',
    // width: "90%",
    fontFamily: "avenir-next",
    flex: 2,
    // borderWidth:1,
    // borderColor: 'red',
  },
});
