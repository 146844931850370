import {
  StyleSheet,
  Image,
  ActivityIndicator,
  useWindowDimensions,
  ScrollView,
  TextInput,
  FlatList,
  Pressable,
  Switch,
} from "react-native";
import React, { useState, useEffect } from "react";
import { BoldText } from "../../constants/StyledText";
import { Text, View } from "../../constants/Themed";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";
import { API, graphqlOperation } from "aws-amplify";

import { Feather } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";

export const createAwardedBadge = /* GraphQL */ `
  mutation CreateAwardedBadge(
    $input: CreateAwardedBadgeInput!
    $condition: ModelAwardedBadgeConditionInput
  ) {
    createAwardedBadge(input: $input, condition: $condition) {
      id
      dateAwarded
      scoringData1Value
      scoringData2Value
      scoringData3Value
      groupingData1Value
      groupingData2Value
      groupingData3Value
      opportunityID

      userID

      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

const AdminHouseCompetitions = ({ navigation }) => {
  const [competitionType, setCompetitionType] = useState("");
  const [firstPlace, setFirstPlace] = useState("");
  const [secondPlace, setSecondPlace] = useState("");
  const [thirdPlace, setThirdPlace] = useState("");
  const [forthPlace, setForthPlace] = useState("");
  const today = new Date().toISOString().slice(0, 10);
  const [firstPlaceHousePoints, setFirstPlaceHousePoints] = useState(50);
  const [secondPlaceHousePoints, setSecondPlaceHousePoints] = useState(30);
  const [thirdPlaceHousePoints, setThirdPlaceHousePoints] = useState(20);
  const [forthPlaceHousePoints, setForthPlaceHousePoints] = useState(10);
  const colorScheme = useColorScheme();



  const opportunityPrefix = "CARRMANOR-OPP-";
  const randomNumber = Math.floor(Math.random() * 10000000);

  const [opportunityID, setOpportunityID] = useState(
    opportunityPrefix.concat(Date.now(), "-", randomNumber)
  );

  
const [houseSpecificUserFirst, setHouseSpecificUserFirst] = useState('')
const [houseSpecificUserSecond, setHouseSpecificUserSecond] = useState('')
const [houseSpecificUserThird, setHouseSpecificUserThird] = useState('')
const [houseSpecificUserForth, setHouseSpecificUserForth] = useState('')

  const [triggerWarning, setTriggerWarning] = useState(false);
  const [logo, setLogo] = useState("public/generic-assets/icon.png");
  const [colorToMatchLogo, setColorToMatchLogo] = useState("");

  const [stopCode, setStopCode] = useState(false);

//upload four records


const firstPlaceDetailsToUpload = {
  dateAwarded: today,
  groupingData1Value: firstPlace,
  opportunityID: competitionType,
  userID: houseSpecificUserFirst,
  scoringData3Value: firstPlaceHousePoints,
}
const secondPlaceDetailsToUpload = {
  dateAwarded: today,
  groupingData1Value: secondPlace,
  opportunityID: competitionType,
  userID: houseSpecificUserSecond,
  scoringData3Value: secondPlaceHousePoints,
}
const thirdPlaceDetailsToUpload = {
  dateAwarded: today,
  groupingData1Value: thirdPlace,
  opportunityID: competitionType,
  userID: houseSpecificUserThird,
  scoringData3Value: thirdPlaceHousePoints,
}
const forthPlaceDetailsToUpload = {
  dateAwarded: today,
  groupingData1Value: forthPlace,
  opportunityID: competitionType,
  userID: houseSpecificUserForth,
  scoringData3Value: forthPlaceHousePoints,
}


  const writeFirstPlaceAwardedBadgeToDatabase = async () => {
    await API.graphql({
      query: createAwardedBadge,
      variables: { input: firstPlaceDetailsToUpload },
    });
  };

  const writeSecondPlaceAwardedBadgeToDatabase = async () => {
    await API.graphql({
      query: createAwardedBadge,
      variables: { input: secondPlaceDetailsToUpload },
    });
  };

  const writeThirdPlaceAwardedBadgeToDatabase = async () => {
    await API.graphql({
      query: createAwardedBadge,
      variables: { input: thirdPlaceDetailsToUpload },
    });
  };
  const writeForthPlaceAwardedBadgeToDatabase = async () => {
    await API.graphql({
      query: createAwardedBadge,
      variables: { input: forthPlaceDetailsToUpload },
    });
  };

useEffect(() => {
  switch (firstPlace) {
    case "Bremner": 
      setHouseSpecificUserFirst("da3ef7e1-1c16-469a-a625-a3eb5e890d16");
      break;
    case "Robinson": 
    setHouseSpecificUserFirst("e69fe25c-3bd9-45a5-9b07-602b7334947f");
      break;
    case "Tomlinson":
      setHouseSpecificUserFirst("e726a29e-e0a1-4ee9-a1e0-e659a9467b7a");
      break;
    case "Trueman": 
    setHouseSpecificUserFirst("0539521d-c54a-453e-b40a-e6eb5fbb30ea");
      break;
    default:
      setHouseSpecificUserFirst("");
  }
}, [firstPlace]);

useEffect(() => {
  switch (secondPlace) {
    case "Bremner": 
      setHouseSpecificUserSecond("da3ef7e1-1c16-469a-a625-a3eb5e890d16");
      break;
    case "Robinson": 
    setHouseSpecificUserSecond("e69fe25c-3bd9-45a5-9b07-602b7334947f");
      break;
    case "Tomlinson":
      setHouseSpecificUserSecond("e726a29e-e0a1-4ee9-a1e0-e659a9467b7a");
      break;
    case "Trueman": 
    setHouseSpecificUserSecond("0539521d-c54a-453e-b40a-e6eb5fbb30ea");
      break;
    default:
      setHouseSpecificUserSecond("");
  }
}, [secondPlace]);

useEffect(() => {
  switch (thirdPlace) {
    case "Bremner": 
      setHouseSpecificUserThird("da3ef7e1-1c16-469a-a625-a3eb5e890d16");
      break;
    case "Robinson": 
    setHouseSpecificUserThird("e69fe25c-3bd9-45a5-9b07-602b7334947f");
      break;
    case "Tomlinson":
      setHouseSpecificUserThird("e726a29e-e0a1-4ee9-a1e0-e659a9467b7a");
      break;
    case "Trueman": 
    setHouseSpecificUserThird("0539521d-c54a-453e-b40a-e6eb5fbb30ea");
      break;
    default:
      setHouseSpecificUserThird("");
  }
}, [thirdPlace]);  

useEffect(() => {
  switch (forthPlace) {
    case "Bremner": 
      setHouseSpecificUserForth("da3ef7e1-1c16-469a-a625-a3eb5e890d16");
      break;
    case "Robinson": 
    setHouseSpecificUserForth("e69fe25c-3bd9-45a5-9b07-602b7334947f");
      break;
    case "Tomlinson":
      setHouseSpecificUserForth("e726a29e-e0a1-4ee9-a1e0-e659a9467b7a");
      break;
    case "Trueman": 
    setHouseSpecificUserForth("0539521d-c54a-453e-b40a-e6eb5fbb30ea");
      break;
    default:
      setHouseSpecificUserForth("");
  }
}, [forthPlace]);  


    const submitRecords = () => {
      var uploadApproveFirstHousePointsNullVariable: boolean = false
      var uploadApproveFirstHousePointsEmptyVariable: boolean = false
      var uploadApproveFirstHousePointsNaNVariable: boolean = false
      var uploadApproveFirstUserNullVariable: boolean = false
      var uploadApproveFirstUserEmptyVariable: boolean = false
      var uploadApproveFirstOppNullVariable: boolean = false
      var uploadApproveFirstOppEmptyVariable: boolean = false

      var uploadApproveSecondHousePointsNullVariable: boolean = false
      var uploadApproveSecondHousePointsEmptyVariable: boolean = false
      var uploadApproveSecondHousePointsNaNVariable: boolean = false
      var uploadApproveSecondUserNullVariable: boolean = false
      var uploadApproveSecondUserEmptyVariable: boolean = false
      var uploadApproveSecondOppNullVariable: boolean = false
      var uploadApproveSecondOppEmptyVariable: boolean = false

      var uploadApproveThirdHousePointsNullVariable: boolean = false
      var uploadApproveThirdHousePointsEmptyVariable: boolean = false
      var uploadApproveThirdHousePointsNaNVariable: boolean = false
      var uploadApproveThirdUserNullVariable: boolean = false
      var uploadApproveThirdUserEmptyVariable: boolean = false
      var uploadApproveThirdOppNullVariable: boolean = false
      var uploadApproveThirdOppEmptyVariable: boolean = false

      var uploadApproveForthHousePointsNullVariable: boolean = false
      var uploadApproveForthHousePointsEmptyVariable: boolean = false
      var uploadApproveForthHousePointsNaNVariable: boolean = false
      var uploadApproveForthUserNullVariable: boolean = false
      var uploadApproveForthUserEmptyVariable: boolean = false
      var uploadApproveForthOppNullVariable: boolean = false
      var uploadApproveForthOppEmptyVariable: boolean = false
      
      
      //first place checks
      if (firstPlaceDetailsToUpload.userID === null) {
        alert("ERROR: No house selected");
      } else {
        uploadApproveFirstUserNullVariable = true 
      }
      if (firstPlaceDetailsToUpload.userID === '') {
        alert("ERROR: No house selected");
      } else {
        uploadApproveFirstUserEmptyVariable = true 
      }
      if (firstPlaceDetailsToUpload.opportunityID === null) {
        alert("ERROR: No award selected");
      } else {
        uploadApproveFirstOppNullVariable = true 
      }
      if (firstPlaceDetailsToUpload.opportunityID === '') {
        alert("ERROR: No award selected");
      } else {
        uploadApproveFirstOppEmptyVariable = true 
      }
      if (firstPlaceDetailsToUpload.scoringData3Value === null) {
        alert("ERROR: Issue with house points");
      } else {
        uploadApproveFirstHousePointsNullVariable = true 
      }
      if (firstPlaceDetailsToUpload.scoringData3Value === '') {
        alert("ERROR: Issue with house points");
      } else {
        uploadApproveFirstHousePointsEmptyVariable = true 
      }
      if (isNaN(firstPlaceDetailsToUpload.scoringData3Value)) {
        alert("ERROR: Issue with house points");
      } else {
        uploadApproveFirstHousePointsNaNVariable = true 
      }


      //second place checks
      if (secondPlaceDetailsToUpload.userID === null) {
        alert("ERROR: No house selected");
      } else {
        uploadApproveSecondUserNullVariable = true 
      }
      if (secondPlaceDetailsToUpload.userID === '') {
        alert("ERROR: No house selected");
      } else {
        uploadApproveSecondUserEmptyVariable = true 
      }
      if (secondPlaceDetailsToUpload.opportunityID === null) {
        alert("ERROR: No award selected");
      } else {
        uploadApproveSecondOppNullVariable = true 
      }
      if (secondPlaceDetailsToUpload.opportunityID === '') {
        alert("ERROR: No award selected");
      } else {
        uploadApproveSecondOppEmptyVariable = true 
      }
      if (secondPlaceDetailsToUpload.scoringData3Value === null) {
        alert("ERROR: Issue with house points");
      } else {
        uploadApproveSecondHousePointsNullVariable = true 
      }
      if (secondPlaceDetailsToUpload.scoringData3Value === '') {
        alert("ERROR: Issue with house points");
      } else {
        uploadApproveSecondHousePointsEmptyVariable = true 
      }
      if (isNaN(secondPlaceDetailsToUpload.scoringData3Value)) {
        alert("ERROR: Issue with house points");
      } else {
        uploadApproveSecondHousePointsNaNVariable = true 
      }
      

           //third place checks
           if (thirdPlaceDetailsToUpload.userID === null) {
            alert("ERROR: No house selected");
          } else {
            uploadApproveThirdUserNullVariable = true 
          }
          if (thirdPlaceDetailsToUpload.userID === '') {
            alert("ERROR: No house selected");
          } else {
            uploadApproveThirdUserEmptyVariable = true 
          }
          if (thirdPlaceDetailsToUpload.opportunityID === null) {
            alert("ERROR: No award selected");
          } else {
            uploadApproveThirdOppNullVariable = true 
          }
          if (thirdPlaceDetailsToUpload.opportunityID === '') {
            alert("ERROR: No award selected");
          } else {
            uploadApproveThirdOppEmptyVariable = true 
          }
          if (thirdPlaceDetailsToUpload.scoringData3Value === null) {
            alert("ERROR: Issue with house points");
          } else {
            uploadApproveThirdHousePointsNullVariable = true 
          }
          if (thirdPlaceDetailsToUpload.scoringData3Value === '') {
            alert("ERROR: Issue with house points");
          } else {
            uploadApproveThirdHousePointsEmptyVariable = true 
          }
          if (isNaN(thirdPlaceDetailsToUpload.scoringData3Value)) {
            alert("ERROR: Issue with house points");
          } else {
            uploadApproveThirdHousePointsNaNVariable = true 
          }




                     //forth place checks
                     if (forthPlaceDetailsToUpload.userID === null) {
                      alert("ERROR: No house selected");
                    } else {
                      uploadApproveForthUserNullVariable = true 
                    }
                    if (forthPlaceDetailsToUpload.userID === '') {
                      alert("ERROR: No house selected");
                    } else {
                      uploadApproveForthUserEmptyVariable = true 
                    }
                    if (forthPlaceDetailsToUpload.opportunityID === null) {
                      alert("ERROR: No award selected");
                    } else {
                      uploadApproveForthOppNullVariable = true 
                    }
                    if (forthPlaceDetailsToUpload.opportunityID === '') {
                      alert("ERROR: No award selected");
                    } else {
                      uploadApproveForthOppEmptyVariable = true 
                    }
                    if (forthPlaceDetailsToUpload.scoringData3Value === null) {
                      alert("ERROR: Issue with house points");
                    } else {
                      uploadApproveForthHousePointsNullVariable = true 
                    }
                    if (forthPlaceDetailsToUpload.scoringData3Value === '') {
                      alert("ERROR: Issue with house points");
                    } else {
                      uploadApproveForthHousePointsEmptyVariable = true 
                    }
                    if (isNaN(forthPlaceDetailsToUpload.scoringData3Value)) {
                      alert("ERROR: Issue with house points");
                    } else {
                      uploadApproveForthHousePointsNaNVariable = true 
                    }
      


                    if (


                      uploadApproveFirstHousePointsNullVariable== true
                      &&uploadApproveFirstHousePointsEmptyVariable== true
                      &&uploadApproveFirstHousePointsNaNVariable== true
                      &&uploadApproveFirstUserNullVariable== true
                      &&uploadApproveFirstUserEmptyVariable== true
                      &&uploadApproveFirstOppNullVariable== true
                      &&uploadApproveFirstOppEmptyVariable== true
                
                      &&uploadApproveSecondHousePointsNullVariable== true
                      &&uploadApproveSecondHousePointsEmptyVariable== true
                      &&uploadApproveSecondHousePointsNaNVariable== true
                      &&uploadApproveSecondUserNullVariable== true
                      &&uploadApproveSecondUserEmptyVariable== true
                      &&uploadApproveSecondOppNullVariable== true
                      &&uploadApproveSecondOppEmptyVariable== true
                
                      &&uploadApproveThirdHousePointsNullVariable== true
                      &&uploadApproveThirdHousePointsEmptyVariable== true
                      &&uploadApproveThirdHousePointsNaNVariable== true
                      &&uploadApproveThirdUserNullVariable== true
                      &&uploadApproveThirdUserEmptyVariable== true
                      &&uploadApproveThirdOppNullVariable== true
                      &&uploadApproveThirdOppEmptyVariable== true
                
                      &&uploadApproveForthHousePointsNullVariable== true
                      &&uploadApproveForthHousePointsEmptyVariable== true
                      &&uploadApproveForthHousePointsNaNVariable== true
                      &&uploadApproveForthUserNullVariable== true
                      &&uploadApproveForthUserEmptyVariable== true
                      &&uploadApproveForthOppNullVariable== true
                      &&uploadApproveForthOppEmptyVariable== true



                    ) {setTriggerWarning(false)

      writeFirstPlaceAwardedBadgeToDatabase()
      writeSecondPlaceAwardedBadgeToDatabase()
      writeThirdPlaceAwardedBadgeToDatabase()
      writeForthPlaceAwardedBadgeToDatabase()
      navigation.navigate("AdminBadgeConfirmation");
    }
    else {
      setTriggerWarning(true)
    }
  }


  return (
    <ScrollView
      contentContainerStyle={{
        width: 900,
        alignSelf: "center",
        alignItems: "center",
        backgroundColor: "transparent",
      }}
    >
      {/* COMPETITION TYPE  */}
      <View style={styles.textInputContainer}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={competitionType ? Colors.palette.logoGreen : "transparent"}
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}
          >
            Competition:
          </Text>
        </View>
        <Picker
          style={[
            styles.textInputBox,
            {
              width: 400,
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
          // itemStyle={{fontFamily: 'avenir-next', color: 'red'}}
          selectedValue={competitionType}
          onValueChange={(itemValue, itemIndex) =>
            setCompetitionType(itemValue)
          }
        >
          <Picker.Item label="Select competition" value="" />
          <Picker.Item
            label="Weekly Attendance Competition"
            value="38e4dd9a-34d5-453a-8281-fda9cd8fbbaa"
          />
          <Picker.Item
            label="Fortnightly Purple Slip Competition"
            value="86cf014d-497d-490a-8674-a993c86ee3a5"
          />
          {/* <Picker.Item
            label="Weekly DoJo Competition"
            value="384e5604-4516-49c7-9b58-8f02f1ea0ba0"
          /> */}
        </Picker>
      </View>

      <View style={{ flexDirection: "row", backgroundColor: "transparent", width: 600,}}>
        
        <View style={[styles.textInputContainer, {width: 350, backgroundColor:'transparent'}]}>
          <View style={styles.textInputLabelAndIconContainer}>
            <Feather
              name="thumbs-up"
              size={18}
              color={firstPlace ? Colors.palette.logoGreen : "transparent"}
            />
            <Text
              style={[
                { color: Colors[colorScheme].textLessEmphasised },
                styles.textInputLabel,
              ]}
            >
              1st Place:
            </Text>
          </View>
          <Picker
            style={[
              styles.textInputBox,
              {
                marginRight: 50,
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            selectedValue={firstPlace}
            onValueChange={(itemValue, itemIndex) => setFirstPlace(itemValue)}
          >
            <Picker.Item label="Select house" value="" />
            <Picker.Item label="Bremner" value={"Bremner"} />
            <Picker.Item label="Robinson" value={"Robinson"} />
            <Picker.Item label="Tomlinson" value={"Tomlinson"} />
            <Picker.Item label="Trueman" value={"Trueman"} />
          </Picker>
        </View>

        {/* HOUSE POINTS  */}
        <View style={[styles.textInputContainer, {width:150}]}>
          <View style={styles.textInputLabelAndIconContainer}>
            {firstPlaceHousePoints ? (
              isNaN(firstPlaceHousePoints) ? (
                <Feather
                  name="alert-triangle"
                  size={18}
                  color={Colors.palette.softRed}
                />
              ) : (
                <Feather
                  name="thumbs-up"
                  size={18}
                  color={Colors.palette.logoGreen}
                />
              )
            ) : (
              <Feather
                name="alert-triangle"
                size={18}
                color={Colors.palette.softRed}
              />
            )}
            <Text
              style={[
                { color: Colors[colorScheme].textLessEmphasised },
                styles.textInputLabel,
              ]}
            >
              House Points:
            </Text>
          </View>
          <TextInput
            style={[
              styles.textInputBox,
              {
                width:50,
                outlineStyle: "none",
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            onChangeText={setFirstPlaceHousePoints}
            maxLength={3}
            value={firstPlaceHousePoints}
          />
        </View>
      </View>

      <View style={{ flexDirection: "row", backgroundColor: "transparent", width: 600,}}>
        
        <View style={[styles.textInputContainer, {width: 350, backgroundColor:'transparent'}]}>
          <View style={styles.textInputLabelAndIconContainer}>
            <Feather
              name="thumbs-up"
              size={18}
              color={secondPlace ? Colors.palette.logoGreen : "transparent"}
            />
            <Text
              style={[
                { color: Colors[colorScheme].textLessEmphasised },
                styles.textInputLabel,
              ]}
            >
              2nd Place:
            </Text>
          </View>
          <Picker
            style={[
              styles.textInputBox,
              {
                marginRight: 50,
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            selectedValue={secondPlace}
            onValueChange={(itemValue, itemIndex) => setSecondPlace(itemValue)}
          >
            <Picker.Item label="Select house" value="" />
            <Picker.Item label="Bremner" value={"Bremner"} />
            <Picker.Item label="Robinson" value={"Robinson"} />
            <Picker.Item label="Tomlinson" value={"Tomlinson"} />
            <Picker.Item label="Trueman" value={"Trueman"} />
          </Picker>
        </View>

        {/* HOUSE POINTS  */}
        <View style={[styles.textInputContainer, {width:150}]}>
          <View style={styles.textInputLabelAndIconContainer}>
            {secondPlaceHousePoints ? (
              isNaN(secondPlaceHousePoints) ? (
                <Feather
                  name="alert-triangle"
                  size={18}
                  color={Colors.palette.softRed}
                />
              ) : (
                <Feather
                  name="thumbs-up"
                  size={18}
                  color={Colors.palette.logoGreen}
                />
              )
            ) : (
              <Feather
                name="alert-triangle"
                size={18}
                color={Colors.palette.softRed}
              />
            )}
            <Text
              style={[
                { color: Colors[colorScheme].textLessEmphasised },
                styles.textInputLabel,
              ]}
            >
              House Points:
            </Text>
          </View>
          <TextInput
            style={[
              styles.textInputBox,
              {
                width:50,
                outlineStyle: "none",
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            onChangeText={setSecondPlaceHousePoints}
            maxLength={3}
            value={secondPlaceHousePoints}
          />
        </View>
      </View>

      <View style={{ flexDirection: "row", backgroundColor: "transparent", width: 600,}}>
        
        <View style={[styles.textInputContainer, {width: 350, backgroundColor:'transparent'}]}>
          <View style={styles.textInputLabelAndIconContainer}>
            <Feather
              name="thumbs-up"
              size={18}
              color={thirdPlace ? Colors.palette.logoGreen : "transparent"}
            />
            <Text
              style={[
                { color: Colors[colorScheme].textLessEmphasised },
                styles.textInputLabel,
              ]}
            >
             3rd Place:
            </Text>
          </View>
          <Picker
            style={[
              styles.textInputBox,
              {
                marginRight: 50,
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            selectedValue={thirdPlace}
            onValueChange={(itemValue, itemIndex) => setThirdPlace(itemValue)}
          >
            <Picker.Item label="Select house" value="" />
            <Picker.Item label="Bremner" value={"Bremner"} />
            <Picker.Item label="Robinson" value={"Robinson"} />
            <Picker.Item label="Tomlinson" value={"Tomlinson"} />
            <Picker.Item label="Trueman" value={"Trueman"} />
          </Picker>
        </View>

        {/* HOUSE POINTS  */}
        <View style={[styles.textInputContainer, {width:150}]}>
          <View style={styles.textInputLabelAndIconContainer}>
            {thirdPlaceHousePoints ? (
              isNaN(thirdPlaceHousePoints) ? (
                <Feather
                  name="alert-triangle"
                  size={18}
                  color={Colors.palette.softRed}
                />
              ) : (
                <Feather
                  name="thumbs-up"
                  size={18}
                  color={Colors.palette.logoGreen}
                />
              )
            ) : (
              <Feather
                name="alert-triangle"
                size={18}
                color={Colors.palette.softRed}
              />
            )}
            <Text
              style={[
                { color: Colors[colorScheme].textLessEmphasised },
                styles.textInputLabel,
              ]}
            >
              House Points:
            </Text>
          </View>
          <TextInput
            style={[
              styles.textInputBox,
              {
                width:50,
                outlineStyle: "none",
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            onChangeText={setThirdPlaceHousePoints}
            maxLength={3}
            value={thirdPlaceHousePoints}
          />
        </View>
      </View>

      <View style={{ flexDirection: "row", backgroundColor: "transparent", width: 600,}}>
        
        <View style={[styles.textInputContainer, {
          width: 350,
           backgroundColor:'transparent'}]}>
          <View style={styles.textInputLabelAndIconContainer}>
            <Feather
              name="thumbs-up"
              size={18}
              color={forthPlace ? Colors.palette.logoGreen : "transparent"}
            />
            <Text
              style={[
                { color: Colors[colorScheme].textLessEmphasised },
                styles.textInputLabel,
              ]}
            >
              4th Place:
            </Text>
          </View>
          <Picker
            style={[
              styles.textInputBox,
              {
                marginRight: 50,
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            selectedValue={forthPlace}
            onValueChange={(itemValue, itemIndex) => setForthPlace(itemValue)}
          >
            <Picker.Item label="Select house" value="" />
            <Picker.Item label="Bremner" value={"Bremner"} />
            <Picker.Item label="Robinson" value={"Robinson"} />
            <Picker.Item label="Tomlinson" value={"Tomlinson"} />
            <Picker.Item label="Trueman" value={"Trueman"} />
          </Picker>
        </View>

        {/* HOUSE POINTS  */}
        <View style={[styles.textInputContainer, {width:150}]}>
          <View style={styles.textInputLabelAndIconContainer}>
            {forthPlaceHousePoints ? (
              isNaN(forthPlaceHousePoints) ? (
                <Feather
                  name="alert-triangle"
                  size={18}
                  color={Colors.palette.softRed}
                />
              ) : (
                <Feather
                  name="thumbs-up"
                  size={18}
                  color={Colors.palette.logoGreen}
                />
              )
            ) : (
              <Feather
                name="alert-triangle"
                size={18}
                color={Colors.palette.softRed}
              />
            )}
            <Text
              style={[
                { color: Colors[colorScheme].textLessEmphasised },
                styles.textInputLabel,
              ]}
            >
              House Points:
            </Text>
          </View>
          <TextInput
            style={[
              styles.textInputBox,
              {
                width:50,
                outlineStyle: "none",
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            onChangeText={setForthPlaceHousePoints}
            maxLength={3}
            value={forthPlaceHousePoints}
          />
        </View>
      </View>

      {/* submit button */}

     
      <View style={{ backgroundColor: "transparent", alignItems: "center" }}>
        {triggerWarning ? (
          <View
            style={{
              backgroundColor: Colors[colorScheme].almostBackground,
              padding: 10,
              borderRadius: 10,
              borderColor: Colors.palette.softRed,
              borderWidth: 1,
              margin: 10,
            }}
          >
            <Text style={{ color: Colors.palette.softRed }}>
              There are some issues with the form. Please fix these before
              trying again.
            </Text>
          </View>
        ) : null}
        <Pressable
          onPress={submitRecords}
          style={{
            alignItems: "center",
            width: 120,
            backgroundColor: Colors.palette.logoGreen,
            padding: 10,
            margin: 20,
            borderRadius: 10,
          }}
        >
          <Feather
            name="upload"
            size={48}
            color={Colors[colorScheme].background}
          />
          <BoldText
            style={{
              textAlign: "center",
              color: Colors[colorScheme].background,
            }}
          >
            Submit details
          </BoldText>
        </Pressable>
      </View>
    </ScrollView>
  );
};
export default AdminHouseCompetitions;

const styles = StyleSheet.create({
  textInputBox: {
    borderWidth: 1,
    margin: 10,
    padding: 10,
    borderRadius: 8,
    fontSize: 16,
    fontFamily: "avenir-next",
    width: 250,
  },
  textInputLabel: { fontSize: 16, marginHorizontal: 10, fontWeight: "800" },
  textInputContainer: {
    flexDirection: "row",
    padding: 5,
    width: 600,
    backgroundColor: "transparent",
    justifyContent: 'flex-start',
    alignItems: "center",
  },
  textInputLabelAndIconContainer: {
    backgroundColor: "tranparent",
    flexDirection: "row",
    alignItems: 'flex-end',
    width: 150,
  },
  switchRowContainer: {
    backgroundColor: "transparent",
    flexDirection: "row",
    flex: 1,
    padding: 5,
    // alignItems: "s",
    justifyContent: "flex-start",
    alignSelf: "stretch",
    marginLeft: 20,
  },
  switchGroupContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    margin: 10,
    padding: 10,
    borderRadius: 8,
    fontSize: 16,
    width: 300,
  },
  switchLabel: { fontSize: 16, marginHorizontal: 40 },
});
